/**
 *@class AccrBlock
 */
import { global as g } from '../../globals.js';
import StdBlock from './StdBlock.js';

export default class AccrBlock extends StdBlock {
  constructor(block, bFrag) {
    super(block, bFrag);
    const { hide, tooltip } = this.block;

    this.restructureElements();

    if (hide) {
      this.bh.classList.add('invisible');
      this.bb.classList.add('invisible');
    }

    g.addTooltip(this.bh, this.block);
    AccrBlock.count++;
  }

  /**
   * Creates li
   * @memberof AccrBlock#
   */
  restructureElements() {
    const { id, parentLoc, breakpoint, width } = this.block;
    let bodyWrap = document.createElement('div');

    if (!AccrBlock.count) {
      //if count = 0
      let parentBb = this.bFrag.querySelector(`#blockb-${parentLoc}`);
      parentBb.classList.add('accordion');
    }

    this.fullB.className = ''; //clear classes
    // in IE11, innerHTML='' deletes children's innerHTML as well, this method needs to be used instead to avoid that issue
    const myNode = this.fullB;
    while (myNode.firstChild) {
      myNode.removeChild(myNode.firstChild);
    }

    if (width !== 'NIL') {
      this.fullB.classList.add('card', `col-${breakpoint}-${width}`);
    } else {
      this.fullB.classList.add('card', `col-${breakpoint}`);
    }

    this.bh.className = '';
    this.bh.classList.add('card-header');
    this.bh.setAttribute('aria-expanded', 'false');
    this.bh.setAttribute('aria-controls', `collapse-accr-${id}`);
    this.bh.dataset.toggle = 'collapse';
    this.bh.dataset.target = `#collapse-accr-${id}`;

    this.bb.classList.add('card-body');

    bodyWrap.id = `collapse-accr-${id}`;
    bodyWrap.classname = '';
    bodyWrap.classList.add('collapse');
    bodyWrap.dataset.parent = `#blockb-${parentLoc}`;
    bodyWrap.setAttribute('aria-labelledby', `blockh-${id}`);

    if (AccrBlock.count) {
      // if !0
      this.bh.classList.add('collapsed');
    } else {
      //if 0
      this.bh.setAttribute('aria-expanded', 'true');
      bodyWrap.classList.add('show');
    }

    bodyWrap.appendChild(this.bb);
    this.fullB.appendChild(this.bh);
    this.fullB.appendChild(bodyWrap);

    AccrBlock.count++;
  }
}

AccrBlock.count = 0;
