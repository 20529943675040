import Chart from "chartjs";

/**
 * @returns
 */
export default function processCharts() {
  const animation = document.getElementById("exo-container").dataset.animation;
  const chartStubs = Array.from(document.querySelectorAll(".ct-chart"));
  // console.log(`Chart stubs: ${chartStubs}`);
  chartStubs.forEach((chartStub) => {
    // if (chartStub.dataset.series) {
    try {
      // const series = chartStub.dataset.series.split('|');
      const series = chartStub.dataset.series;
      // console.log(`Series: ${series}`);
      const aratio = chartStub.dataset.aratio;
      let chart,
        config = {},
        labels = [],
        values = [],
        ctx,
        chartType = "line";
      if (chartStub.classList.contains("pie")) {
        chartType = "pie";
      } else if (chartStub.classList.contains("doughnut")) {
        chartType = "doughnut";
      } else if (chartStub.classList.contains("bar")) {
        chartType = "bar";
      } else if (chartStub.classList.contains("hbar")) {
        chartType = "bar";
      } else if (chartStub.classList.contains("radar")) {
        chartType = "radar";
      } else if (chartStub.classList.contains("scatter")) {
        chartType = "scatter";
      } else if (chartStub.classList.contains("dots")) {
        chartType = "dots";
      } else if (chartStub.classList.contains("bubble")) {
        chartType = "bubble";
      }

      config = getChartConfig(
        series,
        aratio,
        chartStub.classList,
        animation
      );
      config["plugins"] = [];
      // register plugin
      if (config.type === "doughnut") {
        config["plugins"].push(doughnutPlugin);
      } else if (config.type === "bubble") {
        config["plugins"].push(quadrantPlugin);
      }

      // config["plugins"].push(watermarkPlugin);
      // config["plugins"].push(ChartDataLabels);

      if (true) {
        config["plugins"].push(watermarkPlugin);
      }
      if (config.type === "bubble") {
        config["plugins"].push(ChartDataLabels);
      }

      ctx = chartStub.getContext("2d");
      chart = new Chart(ctx, config);
      chartStub.chart = chart;
    } catch (e) {
      console.error(`Failed to init the Chart ${chartStub.id}`);
      console.error(e);
    }
  });
}

/**
 *
 */
export function updateChart(id) {
  const canvas = document.getElementById(id);
  const animation = document.getElementById("exo-container").dataset.animation;
  if (canvas.dataset.series) {
    try {
      const series = canvas.dataset.series;
      // console.log("IN CHART UPDATE");
      const config = getChartConfig(series, 1, "", animation);
      canvas.chart.data = config.data;
      canvas.chart.config.options.subtext = config.options.subtext;
      canvas.chart.config.options.maintainAspectRatio = false;
      canvas.chart.update();
    } catch (e) {
      console.error(`Failed to update the Chart-${id}, ${e}`);
    }
  }
}

/**
 *
 * @param {*} type
 */
function getChartConfig(_cfg, aratio, classList, animation) {
  let cfg = { series: [] };
  // const _cfg = "[type:bar|subtext:value|legend:top|yaxis:hide|xaxis:hide|grid:hide|beginatzero:true|wmark:INERNAL USE|tablename:tbl-92|rel:s1]
  //               [serie:s1|bgcolor:bgs1|type:line|data:one:1::FooBar|data:two:2:bgd2:BarBaz][serie:s2|bgcolor:bgs2|data:one:4:bgd4|data:two:4:bgd5]";
  // console.log(`Chart config: ${_cfg}`);

  let r = /\[([^\[^\]]+),?\]/g;
  let m = r.exec(_cfg);
  let allXvalues = [];

  // iterate match array
  while (m != null) {
    // console.log(`Regex group from chart config: ${m}`);
    if (m[1].trim().length <= 1) {
      // [ or ], or whitespace
      continue;
    }

    let serie = { data: [] };
    if (m[1].trim().startsWith("type")) {
      // parse chart config
      let _c = m[1].trim().split("|");
      for (let j = 0; j < _c.length; j++) {
        let c = _c[j].split(":");
        cfg[c[0].trim()] = c[1].trim();
      }
      // console.log(type);
    } else if (m[1].trim().startsWith("serie")) {
      // parse series
      let _serie = m[1].trim().split("|");
      // console.log(_serie);

      for (let j = 0; j < _serie.length; j++) {
        // parse datapoints
        if (_serie[j].trim().startsWith("data")) {
          let d = {};
          let _d = _serie[j].split(":");
          try {
            d.xvalue = _d[1].trim();
            d.yvalue = _d[2].trim();
            if (_d.length === 4) {
              d.bgcolor = _d[3].trim();
            }
            if (_d.length === 5) {
              d.label = _d[4].trim();
            }

            if (cfg.type === "bubble") {
              // console.log(_d[4]);
              // console.log(_d[5]);
              d.rvalue = _d[4].trim();
              d.lvalue = _d[5].trim();
            }

            serie.data.push(d);

            // edn 20230116 add value only if it does not
            // exist in array of all X values
            // if (!allXvalues.includes(d.xvalue)) {
            //   allXvalues.push(d.xvalue);
            // }

            allXvalues.push(d.xvalue);
          } catch (e) {
            //ignore
          }
        } else {
          // parse series name and color
          let s = _serie[j].split(":");
          try {
            serie[s[0].trim()] = s[1].trim();
          } catch (e) {
            // ignore
          }
        }
      }
      // console.log(serie);
      cfg.series.push(serie);
    }
    m = r.exec(_cfg);
  }

  // edn 20230116 we do not need the line below as we are
  // distincting values when we add them to the allXvalues array
  // const distinctXvalues = allXvalues;

  const distinctXvalues = [
    ...new Set(
      allXvalues.sort(function (a, b) {
        return Number(a) - Number(b);
      })
    ),
  ];
  let serieTempate = [];
  distinctXvalues.forEach((v) => {
    let c = {};
    if (cfg.type === "hbar") {
      // need to swap x and y for a horizontal bar
      c.y = v;
      c.x = null;
    } else {
      c.x = v;
      c.y = null;
    }
    serieTempate.push(c);
  });
  // console.log("serieTempate");
  // console.log(serieTempate);

  let config = { type: cfg.type },
    datasets = [],
    labels = [];
  let dotStyles = [
    "circle",
    "rect",
    "rectRot",
    "triangle",
    "star",
    "cross",
    "crossRot",
  ];
  cfg.series.forEach((s, idx) => {
    let dataset = {},
      bgColor = [],
      borderColor = [],
      pointBackgroundColor = [],
      pointRadius = [];
    let data = [];
    let dotStyleIdx =
      idx < dotStyles.length ? idx : (idx + 1) % dotStyles.length;

    if (s.serie) {
      dataset.label = s.serie;
    }

    if (s.type) {
      dataset.type = s.type;
    }

    s.data.forEach((d) => {
      // pie chart does not like data to contain label:value pairs
      if (
        cfg.type === "pie" ||
        cfg.type === "doughnut" ||
        cfg.type === "radar"
      ) {
        if (idx === 0) {
          // do this for the first serie only
          labels.push(d.xvalue);
        }
        data.push(d.yvalue);
      } else if (cfg.type === "bubble") {
        let c = {};
        c.x = d.xvalue;
        c.y = d.yvalue;
        c.r = d.rvalue;
        c.l = d.lvalue;
        // c.backgroundColor = d.bgcolor ? d.bgcolor : s.bgcolor;
        data.push(c);
      } else if (cfg.type === "hbar") {
        // need to swap x and y for a horizontal bar
        let c = {};
        c.x = d.yvalue;
        c.y = d.xvalue;
        data.push(c);
      } else {
        // data[d.xvalue] = d.yvalue;
        let c = {};
        c.x = d.xvalue;
        c.y = d.yvalue;
        if (d.label) {
          c.label = d.label;
        }
        data.push(c);
      }

      if (config.type === "dots") {
        pointBackgroundColor.push(d.bgcolor ? d.bgcolor : s.bgcolor);
        pointRadius.push(d.yvalue.trim().length === 0 ? 0 : 8);
      }
      borderColor.push(d.bgcolor ? d.bgcolor : s.bgcolor);
      bgColor.push(d.bgcolor ? d.bgcolor : s.bgcolor);
    });

    if (cfg.type === "pie" || cfg.type === "doughnut" || cfg.type === "radar") {
      dataset.data = data;
    } else {
      // this helps with keeping proper order of x-values
      dataset.data = serieTempate.concat(data);
      // console.log(dataset.data);
    }

    dataset.backgroundColor = bgColor;
    dataset.borderColor = borderColor;
    if (pointBackgroundColor.length > 0) {
      dataset.pointBackgroundColor = pointBackgroundColor;
    }

    if (pointRadius.length > 0) {
      dataset.pointRadius = pointRadius;
    }

    // set defaults
    switch (config.type) {
      case "dots":
        dataset.showLine = false;
        dataset.fill = false;
        dataset.pointStyle = dotStyles[dotStyleIdx];
        break;
      case "radar":
      case "line":
        dataset.fill = false;
        dataset.borderWidth = 2;
        dataset.borderColor = s.bgcolor;
        break;
      case "hbar":
      case "bar":
        dataset.borderWidth = 1;
        dataset.barPercentage = 0.4;
        break;
      case "pie":
      case "doughnut":
        dataset.borderWidth = 1;
        break;
      case "scatter":
        break;
    }

    datasets.push(dataset);
  });
  config.data = {};
  config.data.datasets = datasets;
  //
  if (cfg.type === "pie" || cfg.type === "doughnut" || cfg.type === "radar") {
    config.data.labels = labels;
  }

  switch (config.type) {
    // LINE CHART
    case "line":
      config.options = {
        aspectRatio: aratio,
        plugins: {
          tooltip: {
            callbacks: {
              title: ttTitle,
              label: ttLabel,
              afterLabel: ttSfterLabel
            }
          }
        },
        scales: {
          y: {
            grid: {
              display:cfg.grid && cfg.grid === "hide" ? false : true,
            },
            beginAtZero: cfg.beginatzero && cfg.beginatzero === 'false' ? false : true,
            ticks: {
              callback: function (val, index) {
                // Hide the label of every 2nd value
                return index % 2 === 0 ? val : "";
              },
            },
            display: cfg.yaxis && cfg.yaxis === "hide" ? false : true,
          },
          x: {
            grid: {
              display:cfg.grid && cfg.grid === "hide" ? false : true,
            },
            display:cfg.xaxis && cfg.xaxis === "hide" ? false : true,
          }
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
      };
      break;

    case "dots":
      config.type = "line";
      config.options = {
        aspectRatio: aratio,
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            callbacks: {
              title: ttTitle,
              label: ttLabel,
              afterLabel: ttSfterLabel
            } 
          }
        },
        elements: {
          point: {
            hoverRadius: 10,
          },
        },
        scales: {
          y: {
            grid: {
              display:cfg.grid && cfg.grid === "hide" ? false : true,
            },
            beginAtZero: cfg.beginatzero && cfg.beginatzero === 'false' ? false : true,
            ticks: {
              callback: function (val, index) {
                // Hide the label of every 2nd value
                return index % 2 === 0 ? val : "";
              },
            },
            display: cfg.yaxis && cfg.yaxis === "hide" ? false : true,
          },
          x: {
            grid: {
              display:cfg.grid && cfg.grid === "hide" ? false : true,
            },
            display:cfg.xaxis && cfg.xaxis === "hide" ? false : true,
          }
        }
      };
      break;

    case "scatter":
      config.options = {
        elements: {
          point: {
            radius: 3,
            hoverRadius: 5,
          },
        },
        scales: {
          x: {
            type: "linear",
            position: "bottom",
          },
        },
      };

      break;

    // RADAR CHART
    case "radar":
      config.options = {
        elements: {
          line: {
            borderWidth: 2,
          },
          point: {
            radius: 1,
            hoverRadius: 3,
          },
        },
        scales: {
          r: {
            startAngle: 45,
            beginAtZero: cfg.beginatzero && cfg.beginatzero === 'false' ? false : true,
            ticks: {
            },
          },
        },
        r: {
          angleLines: {
            display: false,
          },
          suggestedMin: 50,
          suggestedMax: 100,
        },
      };
      break;

    // BAR CHART
    case "bar":
      config.options = {
        aspectRatio: aratio,
        barValueSpacing: 10,
        scales: {
          y: {
            beginAtZero: cfg.beginatzero && cfg.beginatzero === 'false' ? false : true,
            ticks: {
              callback: function (val, index) {
                // Hide the label of every 2nd value
                return index % 2 === 0 ? val : "";
              },
            },
            display: cfg.yaxis && cfg.yaxis === "hide" ? false : true,
          },
          x: {
            grid: {
              display:cfg.grid && cfg.grid === "hide" ? false : true,
            },
            display:cfg.xaxis && cfg.xaxis === "hide" ? false : true,
          }
        },
      };
      break;

    // HORIZONTAL BAR CHART
    case "hbar":
      config.type = "bar";
      config.options = {
        aspectRatio: aratio,
        barValueSpacing: 10,
        indexAxis: "y",
        scales: {
          x: {
            beginAtZero: cfg.beginatzero && cfg.beginatzero === 'false' ? false : true,
            ticks: {
              callback: function (val, index) {
                // Hide the label of every 2nd value
                return index % 2 === 0 ? val : "";
              },
            },
            display: cfg.xaxis && cfg.xaxis === "hide" ? false : true,
          },
          y: {
            grid: {
              display:cfg.grid && cfg.grid === "hide" ? false : true,
            },
            display:cfg.yaxis && cfg.yaxis === "hide" ? false : true,
          }
        },
      };
      break;

    // PIE CHART
    case "pie":
      config.options = {
        aspectRatio: 1,
      };
      break;

    // DOUGHNUT CHART
    case "doughnut":
      config.options = {
        cutout: cfg.cutout + "%",
        aspectRatio: 1,
        subtext: cfg.subtext,
      };
      break;

    case "bubble":
      config.options = {
        aspectRatio: aratio,
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            /*
            anchor: function (context) {
                return 'center';
            },
            */
            textAlign: function (context) {
              return "center";
            },
            color: function (context) {
              return "white";
            },
            formatter: function (value) {
              // console.log(value);
              return value.l.replaceAll("\\n", "\n");
            },
            offset: 2,
            padding: 0,
          },
          quadrant: {
            topLeft: "red",
            topRight: "blue",
            bottomRight: "green",
            bottomLeft: "yellow",
          },
        },
        scales: {
          y: {
            beginAtZero: false,
            // display: cfg.yaxis && cfg.yaxis === 'hide' ? false : true,
            display: false,
            ticks: {
              display: false,
              // maxTicksLimit: 3
            },
          },
          x: {
            beginAtZero: false,
            // display: cfg.xaxis && cfg.xaxis === 'hide' ? false : true,
            display: false,
            ticks: {
              display: false,
              // maxTicksLimit: 3
            },
          },
        },
      };
      break;
  }

  // legend
  if (!config.options.plugins) {
    config.options.plugins = {};
  }

  if (!config.options.plugins.legend) {
    config.options.plugins.legend = {};
  }

  if (cfg.legend && cfg.legend !== "hide") {
    config.options.plugins.legend.display = true;
    config.options.plugins.legend.position = cfg.legend;
  } else {
    config.options.plugins.legend.display = false;
  }

  if (cfg.wmark && cfg.wmark.length > 0) {
    config.options["watermark"] = { rotate: 22.5, text: cfg.wmark };
  }

  // disable animation for the chart

  if (animation === "0") {
    config.options["animation"] = { duration: 0 };
  }

  return config;
}

/**
 * 
 * @param {*} context 
 * @returns 
 */
const ttTitle = (context) => {
  return '';
}

/**
 * 
 * @param {*} context 
 * @returns 
 */
const ttLabel = (context) => {
  let label = context.dataset.label || '';
  if (context.raw.label !== null && context.raw.label !== undefined) {
    label += ': ' + context.raw.label;
  }
  return label;
}

/**
 * 
 * @param {*} context 
 * @returns 
 */
const ttSfterLabel = (context) => {
  let after = [];
  after.push(`x: ${context.label}`);
  after.push(`y: ${context.formattedValue}`);

  return after;
}

const quadrantPlugin = {
  id: 'quadrant',
  beforeDraw(chart, args, options) {
    const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;
    const midX = (left+right)/2;
    const midY = (top+bottom)/2;

    // console.log(`midX:${midX}, midY:${midY}, left:${left}, top:${top}, right:${right}, bottom:${bottom}, x:${x}, y:${y}`);

    ctx.save();

      // set line stroke and line width
    ctx.strokeStyle = '#0071c5';
    ctx.lineWidth = 1;

    // draw a red line
    ctx.beginPath();
    ctx.moveTo(midX,top);
    ctx.lineTo(midX, bottom);
    ctx.stroke();

    ctx.strokeStyle = '#0071c5';
    ctx.lineWidth = 1;    
    ctx.beginPath();
    ctx.moveTo(left, midY);
    ctx.lineTo(right,midY);
    ctx.stroke();

    ctx.font = "12px Intel Clear";
    // Show the different textAlign values
    ctx.textAlign = "end";
    ctx.fillText("Best Performance Best TCO", right, midY-10);

    ctx.fillStyle = 'rgba(240, 240, 240, 0.4)';
    ctx.fillRect(midX, top, right - midX, midY - top);
            
    ctx.restore();
  }
};

var doughnutPlugin = {
  beforeDraw: function (chart) {
    var width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    var fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em helvetica";
    ctx.textBaseline = "middle";
    var text = chart.config.options.subtext,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;
    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

/*
 *
 */
var watermarkPlugin = {
  defaultOptions: {
    // where to draw the text (if null, center text on chart)
    x: null,
    y: null,
    rotate: 0,

    // font: Chart.helpers.fontString(48, 'bold', Chart.defaults.font.family),
    fillStyle: "rgba(0,0,0,0.2)",
    textAlign: "center",
    textBaseline: "middle",
    text: "",
  },

  afterDraw: function (chart) {
    // console.log("AFTER DRAW WATERMARK PLUGIN");
    var watermark = chart.watermark;
    var fontSize = (chart.height / 10).toFixed(2);
    // console.log(`Font size: ${fontSize}`);
    if (watermark.text) {
      var ctx = chart.ctx;
      ctx.save();

      // ctx.font = watermark.font;
      ctx.font = Chart.helpers.fontString(
        fontSize,
        "bold",
        Chart.defaults.font.family
      );
      ctx.fillStyle = watermark.fillStyle;
      ctx.textAlign = watermark.textAlign;
      ctx.textBaseline = watermark.textBaseline;

      var canvas = chart;
      var x = watermark.x || canvas.width / 2;
      var y = watermark.y || canvas.height / 2;
      ctx.translate(x, y);
      ctx.rotate((watermark.rotate * Math.PI) / 180);

      ctx.fillText(watermark.text, 0, 0);
      ctx.restore();
    }
  },

  beforeInit: function (chart) {
    // console.log("BEFORE INIT WATERMARK PLUGIN");
    var plugin = this;
    chart.watermark = {};
    var helpers = Chart.helpers,
      options = chart.options;
    if (options.watermark) {
      var clonedDefaultOptions = helpers.clone(plugin.defaultOptions);
      var watermark = Object.assign(clonedDefaultOptions, options.watermark);
      chart.watermark = watermark;
    }
  },
};
