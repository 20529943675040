import { global as g } from "../globals.js";

export default function loadCss(fileName) {
  let masterCss = document.createElement("link");
  try {
    document.getElementById('exoMasterCss').remove();
  } catch(e){
    // console.info("Failed to remove master css reference");
  }
  
  masterCss.id = 'exoMasterCss';
  masterCss.rel = 'stylesheet';
  masterCss.type = 'text/css';
  masterCss.href = `${g.baseUrl}/resources/css/${fileName}`;
  

  document.getElementsByTagName("head")[0].appendChild(masterCss);
}

"<'row'<'col-sm-24 col-md-12'l><'col-sm-24 col-md-12'f>>" +
  "<'row'<'col-sm-24'tr>>" +
  "<'row'<'col-sm-24 col-md-10'i><'col-sm-24 col-md-14'p>>";