/**
 * @class CardBlock
 */
// import { global as g } from '../../globals.js';
import StdBlock from './StdBlock';

export default class CardBlock extends StdBlock {

    constructor(block, bFrag) {
        super(block, bFrag);
    }

    /**
     * 
     */
    buildBlockH() {

        const { id, label, headerCssId, initState } = this.block;
        const bh = document.createElement('header');
        bh.id = `blockh-${id}`;
        bh.classList.add('card-title');

        if (label) {
            // let labelContent; // blockHeaderRow, blockHeaderColumn
            // labelContent = document.createElement('div');
            if (headerCssId) {
                // labelContent.classList.add(headerCssId);
                bh.classList.add(headerCssId);
            }
            // labelContent.appendChild(document.createRange().createContextualFragment(label));
            
            // bh.appendChild(labelContent);
            bh.appendChild(document.createRange().createContextualFragment(label));
        }

        return bh;
    }

    /** @memberof StdBlock# */
    buildBlockB() {
        const { id, bodyCssId } = this.block;
        const bb = document.createElement('div');
        bb.id = `blockb-${id}`;
        bb.classList.add('card-text');

        if (bodyCssId) {
            bb.classList.add(bodyCssId);
        }

        return bb;
    }

    /** @memberof StdBlock# */
    buildFullBlock() {
        const { id, cssId, type, width, breakpoint, hide, parentLoc } = this.block;
        const bFrag = this.bFrag;
        let isFlipCard;

        const colDiv = document.createElement('div'); //
        if (width !== 'NIL') {
            colDiv.classList.add(`col-${breakpoint}-${width}`); //
            // colDiv.classList.add(`col-${breakpoint}-${width}`, 'mb-1'); //
        } else {
            colDiv.classList.add(`col-${breakpoint}`);
            // colDiv.classList.add(`col-${breakpoint}`,'mb-1');
        }

        try {
            // isFlipCard = bFrag.getElementById(`block-${parentLoc}`).dataset.blockType === 'FCRD' ? 1 : 0;
            isFlipCard = bFrag.querySelector(`#block-${parentLoc}`).dataset.blockType === 'FCRD' ? 1 : 0;
            // console.log(`isFlipCard = ${isFlipCard}`);
        } catch (e) {
            console.log("Failed to get parent block type. ", e);
        }

        const fullB = isFlipCard ? document.createElement('div') : document.createElement('section');
        fullB.dataset.flipCard = isFlipCard;
        const comment = document.createComment(`BLOCK ${id} starts on section right above.`);
        fullB.id = `block-${id}`;

        if (isFlipCard) {
            let cardFront = bFrag.querySelector(`#block-${parentLoc} > .flip > .card-front`);
            if (cardFront) {
                // front card exists - add as back card
                fullB.classList.add('card-back');
            } else {
                fullB.classList.add('card-front', 'card-abs');
            }
        } else {
            // fullB.classList.add('card', 'h-100', 'mb-1', 'block-wrapper');
            fullB.classList.add('card', 'h-100', 'block-wrapper');
        }

        fullB.dataset.blockHide = hide;
        fullB.dataset.blockType = type;
        fullB.dataset.role = "card";
        fullB.appendChild(comment);

        const cardBody = document.createElement('div');
        cardBody.classList.add('card-body');
        if (cssId) {
            cardBody.classList.add(cssId);
        }
        cardBody.appendChild(this.bh);
        cardBody.appendChild(this.bb);

        fullB.appendChild(cardBody);

        if (!isFlipCard) {
            colDiv.appendChild(fullB);
            return colDiv;
        } else {
            return fullB;
        }
    }

    /**
     * 
     */
    appendBlock() {
        // console.log(this.block);
        const { parentLoc } = this.block;
        const bFrag = this.bFrag;
        let parentRow;

        this.isCollapsable();
        // console.log(this.fullB);
        let isFlipCard = false;
        try {    
            isFlipCard = this.fullB.firstChild.dataset.flipCard;
        } catch(e) {
            isFlipCard = this.fullB.dataset.flipCard;
        }
        // console.log(`isFlipCard from dataset ${isFlipCard}`);

        if (parentLoc) {
            if (!parseInt(isFlipCard)) {
                // console.log(`appending regular card`);
                // check for row in parent, if there is none, create it before appending
                // to it
                parentRow = bFrag.querySelector(`#blockb-${parentLoc} > .row`);
                if (!parentRow) {
                    parentRow = document.createElement('div');
                    parentRow.classList.add('row');
                    try {
                        bFrag.querySelector(`#blockb-${parentLoc}`).appendChild(parentRow);
                    } catch (e) {
                        console.warn(`Failed to append parent row div for #blockb-${parentLoc}`);
                    }
                }
            } else {
                // console.log(`appending flip card`);
                parentRow = bFrag.querySelector(`#blockb-${parentLoc}`);
            }
            if (parentRow)
                parentRow.appendChild(this.fullB);
        }
        // end appendBlock
    }

}