/**
 * @class TabBlock
 */
import { global as g } from '../../globals.js';

export default class TabBlock {

  constructor(block, bFrag) {
    this.block = block;
    this.bFrag = bFrag;

    const { hide, tooltip } = this.block;

    this.bh = this.createLi();// header
    this.bb = this.createTabPaneBody();// body, tabPane

    if (hide) {
// this.bh.classList.add('invisible');
// this.bb.classList.add('invisible');
      this.bh.classList.add('d-none');
      this.bb.classList.add('d-none');
    }

    g.addTooltip(this.bh, this.block);
  }

  /**
   * Creates li>a if not navTabPullDown, else creates only anchor (<a>)
   * 
   * @memberof TabBlock#
   */
  createLi() {
    const { id, label, navCssId, navTabPulldown } = this.block;
    const a = document.createElement('a');

    if (navTabPulldown) {
      a.id = `blockh-${id}`;
      a.href = `#blockb-${id}`;
      a.classList.add('dropdown-item');
      a.textContent = label;
      a.setAttribute('role', 'tab');
      a.dataset.toggle = 'tab';
      return a;
    }

    const li = document.createElement('li');

    li.id = `blockh-${id}`;
    li.classList.add('nav-item');
// if (navCssId && navCssId !== 'NIL')
// li.classList.add(navCssId);

    a.classList.add('nav-link');
    
    if (navCssId && navCssId !== 'NIL')
      a.classList.add(navCssId);
    
    a.dataset.toggle = 'tab';
    a.href = `#blockb-${id}`;
    a.setAttribute('role', 'tab');
    a.setAttribute('aria-controls', `blockb-${id}`);
    a.setAttribute('aria-selected', false);
// a.textContent = label;
    a.appendChild(document.createRange().createContextualFragment(label));

    li.appendChild(a);
    return li;
  }

  createTabPaneBody() {
    const { id } = this.block;

    const divTabPane = document.createElement('div');

    divTabPane.id = `blockb-${id}`;
    // divTabPane.classList.add('tab-pane', 'fade');
    divTabPane.classList.add('tab-pane');
    divTabPane.setAttribute('role', "tabpanel");
    divTabPane.setAttribute('aria-labelledby', `blockb-${id}`);

    return divTabPane;
  }

  /**
   * On parent's body (#blockb-parentLoc), uses an existing nav or creates one
   * Invoked by buildBlocks.js
   * 
   * @memberof TabBlock#
   */
  appendToNav() {
    const { parentLoc, id, navDir, navTabPulldown } = this.block;

    if (navTabPulldown) { // if dropdown
      let nav = this.bFrag.querySelector(`#blockh-${parentLoc} nav`);
      if (nav) {
        let tabContentParent = findTabContentElement(this.block, this.bFrag);

        nav.appendChild(this.bh);
        tabContentParent.appendChild(this.bb);

      } else {
        const navParent = this.bFrag.querySelector(`#blockb-${parentLoc}`).parentElement;
        const a = this.bh;
        const navParentHeader = this.bFrag.querySelector(`#blockh-${parentLoc}`);
        // const navParentHeader =
        // navParent.querySelector(`#blockh-${parentLoc}`);
        const parentA = navParentHeader.querySelector('a.nav-link');

        navParentHeader.classList.add('dropdown');

        parentA.classList.add('dropdown-toggle');
        parentA.href = `#`;
        parentA.setAttribute('data-toggle', 'dropdown');
        parentA.setAttribute('role', 'button');
        parentA.setAttribute('aria-haspopup', 'true');
        parentA.setAttribute('aria-expanded', 'false');
        parentA.removeAttribute('aria-controls');

        a.setAttribute('role', 'tab');
        a.dataset.toggle = 'tab';

        nav = createNav(this.block);
        nav.appendChild(a);
        navParentHeader.appendChild(nav);
        navParent.appendChild(this.bb);
      }

    } else { // if !navTabPulldown (if not pulldown)
      let nav = this.bFrag.querySelector(`#blockb-${parentLoc} nav`);

      if (nav) {
        let tabContent = this.bFrag.querySelector(`#tabb-${parentLoc}`);
        nav.querySelector('ul').appendChild(this.bh);
        tabContent.appendChild(this.bb);

      } else {
        const navParent = this.bFrag.querySelector(`#blockb-${parentLoc}`);
        const tabContent = createTabContent(parentLoc, id);
        const a = this.bh.querySelector('a');

        // set active tab
        a.setAttribute('aria-selected', true);
        a.classList.add('active');
        this.bb.classList.add('show', 'active');
        // line below fixed the problem with active page display
        // on page load with intel's header and footer
        this.bb.classList.add('in','active');

        nav = createNav(this.block);
        nav.querySelector('ul').appendChild(this.bh);
        tabContent.appendChild(this.bb);

        if (navDir === "V") {
          const rowDiv = document.createElement('div');

          rowDiv.classList.add('row');
          nav.classList.add('col-md-6');
          tabContent.classList.add('col-md-18');

          rowDiv.appendChild(nav);
          rowDiv.appendChild(tabContent);
          navParent.appendChild(rowDiv);

        } else {
          navParent.appendChild(nav);
          navParent.appendChild(tabContent);
        }
      }
    }

    g.isTopLvlContainer = true;
  }

}

/**
 * Check if parent has tab content, if not, search up to 5 parents until it is
 * found, otherwise return parent elemnt
 * 
 * @param {object}
 *          thisBlock - { block }
 * @param {object}
 *          bFrag - Block <fragment>
 * @function [findTabContentElement]
 * @name findTabContentElement
 * @memberof TabBlock#
 */
function findTabContentElement(thisBlock, bFrag) {

  const { parentLoc } = thisBlock;
  const classToFind = 'tab-content';
  // const classToFind = 'exo-container';//test

  let tabContentParent = bFrag.querySelector(`#blockb-${parentLoc}`);

  for (let i = 0; i < 5; i++) {
    if (tabContentParent.classList.contains(classToFind)) {
      return tabContentParent;
    }
    tabContentParent = tabContentParent.parentElement;
  }

  return bFrag.querySelector(`#blockb-${parentLoc}`);
}

/**
 * Used only first time when navbar is created by the first type:TAB
 * .tab-content holds the content for the navigation bar Inside body of parent
 * (e.g. blockb-2), Creates body of nav block. Other blocks nested here. Used
 * only first time when navbar is created by the first type:TAB
 * 
 * @function TabBlock#
 */
function createTabContent(parentLoc) {
  const tabContent = document.createElement('section');
  tabContent.id = `tabb-${parentLoc}`;
  tabContent.classList.add('tab-content');
  return tabContent;
}

/**
 * Used only first time when navbar is created by the first type:TAB or dropdown
 * 
 * @function TabBlock#
 */
function createNav(block) {

  const { parentLoc, navType, navDir, navJustify, navTabPulldown } = block;
  const nav = document.createElement('nav');

  if (navTabPulldown) {
    nav.classList.add('dropdown-menu');
    return nav;
  }

  const ul = document.createElement('ul');

  nav.id = `tabhNav-${parentLoc}`;
  ul.id = `tabh-${parentLoc}`;
  ul.classList.add('nav');

  if (navType === 'TAB')
    ul.classList.add('nav-tabs');// will navType bring nav-tabs
  else if (navType === "PILL")
    ul.classList.add('nav-pills');

  if (navDir === "V")
    ul.classList.add('flex-column');

  if (navJustify)
    ul.classList.add('nav-justified');
  // ul.classList.add('nav-fill');

  ul.setAttribute('role', 'tablist');
  nav.appendChild(ul);
  return nav;
}

// div.row > nav.col-xx + main.col-xx

/*
 * PARENT li > a = div.dropdown-menu li .classlist.add('dropdown') on anchor
 * would need to change data-toggle=tab to =dropdown add class dropdown-toggle
 * role=button aria-haspopup=true aria-expanded=false CHILD =, on
 * div.dropdown-menu > a.dropdown-item class=dropdown-menu href=tab
 */