/**
 * 
 */
export default function validateInput(el) {

  let tmpStr = '', re = null, message = '', mask = '';

  if (el.value === '') {// de-select to null is a valid operation
    return {
      'valid' : true,
      'msg' : ''
    };
  }

  if (el.dataset.minv && el.dataset.minv !== '')
    tmpStr += ' Min Value: ' + el.dataset.minv;
  if (el.dataset.maxv && el.dataset.maxv !== '')
    tmpStr += ' Max Value: ' + el.dataset.maxv;
  if (el.dataset.minl && el.dataset.minl !== '')
    tmpStr += ' Min Length: ' + el.dataset.minl;
  if (el.dataset.maxl && el.dataset.maxl !== '')
    tmpStr += ' Max Length: ' + el.dataset.maxl;

// console.log(`MASK from page config:
// ${document.body.pageConfig.validatorErrorMsg}`);
  mask = document.body.pageConfig.validatorErrorMsg.length > 0 && document.body.pageConfig.validatorErrorMsg !== 'NIL'
      ? document.body.pageConfig.validatorErrorMsg : '';
  message += mask + tmpStr;

  if (el.dataset.expr && el.dataset.expr !== '') {
    re = new RegExp(el.dataset.expr);
    if (!re.test(el.value)) {
      if (el.dataset.expfailmsg != '' && el.dataset.expfailmsg !== 'NIL') {
        message += el.dataset.expfailmsg
      }
      return {
        'valid' : false,
        'msg' : message
      };
    }
  }

  if (el.dataset.minv && el.dataset.minv !== '') {
    if (!testMinValue(el))
      return {
        'valid' : false,
        'msg' : message
      };
  }

  if (el.dataset.maxv && el.dataset.maxv !== '') {
    if (!testMaxValue(el)) {
      return {
        'valid' : false,
        'msg' : message
      };
    }
  }

  if (el.dataset.minl && el.dataset.minl !== '') {
    if (!testMinLength(el)) {
      return {
        'valid' : false,
        'msg' : message
      };
    }
  }

  if (el.dataset.maxl && el.dataset.maxl !== '') {
    if (!testMaxLength(el)) {
      return {
        'valid' : false,
        'msg' : message
      };
    }
  }

  return {
    'valid' : true,
    'msg' : ''
  };
}

/**
 * 
 */
function testMinValue(el) {

  let result = '', greaterThanEqual = null, greaterThan = null;

  if (isNaN(el.value)) {
    return false;
  }

  if (el.dataset.minv.indexOf(">=") >= 0) {
    result = el.dataset.minv.split('=');
    greaterThanEqual = result[1];
    if (Number(el.value) < Number(greaterThanEqual)) {
      return false;
    }
  } else if (el.dataset.minv.indexOf(">") >= 0) {
    result = el.dataset.minv.split('>');
    greaterThan = result[1];
    if (Number(el.value) <= Number(greaterThan)) {
      return false;
    }
  } else {
    // there is no sign take less than equal minimum as false
    if (Number(el.value) <= Number(el.dataset.minv)) {
      return false;
    }
  }

  return true;
}

/**
 * 
 */
function testMaxValue(el) {

  let result = '', lessThanEqual = null, lessThan = null;

  if (isNaN(el.value))
    return false;

  if (el.dataset.maxv.indexOf("<=") >= 0) {
    result = el.dataset.maxv.split('=');
    lessThanEqual = result[1];
    if (Number(el.value) > Number(lessThanEqual)) {
      return false;
    }
  } else if (el.dataset.maxv.indexOf("<") >= 0) {
    result = el.dataset.maxv.split('<');
    lessThan = result[1];
    if (Number(el.value) >= Number(lessThan)) {
      return false;
    }
  } else {
    // there is no sign take greater than equal minimum as false
    if (Number(el.value) >= Number(el.dataset.maxv)) {
      return false;
    }
  }

  return true;
}

/**
 * 
 */
function testMinLength(el) {

  let result = '', greaterThanEqual = null, greaterThan = null;

  if (el.dataset.minl.indexOf(">=") >= 0) {
    result = el.dataset.minl.split('=');
    greaterThanEqual = result[1];
    if (el.value.length < Number(greaterThanEqual)) {
      return false;
    }
  } else if (el.dataset.minl.indexOf(">") >= 0) {
    result = el.dataset.minl.split('>');
    greaterThan = result[1];
    if (el.value.length <= Number(greaterThan)) {
      return false;
    }
  } else {
    if (el.value.length < Number(el.dataset.minl)) {
      // there is no sign take less than equal minimum as false
      return false;
    }
  }
  return true;
}

/**
 * 
 */
function testMaxLength(el) {

  let result = '', lessThanEqual = null, lessThan = null;

  if (el.dataset.maxl.indexOf("<=") >= 0) {
    result = el.dataset.maxl.split('=');
    lessThanEqual = result[1];
    if (el.value.length > Number(lessThanEqual)) {
      return false;
    }
  } else if (el.dataset.maxl.indexOf("<") >= 0) {
    result = el.dataset.maxl.split('<');
    lessThan = result[1];
    if (el.value.length >= Number(lessThan)) {
      return false;
    }
  } else {
    if (el.value.length > Number(el.dataset.maxl)) {
      return false;
    }
  }

  return true;
}