/**
 * 
 */
function showSpinner(el) {
  try {
    let spinner = document.createElement('div');
      spinner.classList.add('spinner-border','spinner-border-sm','text-primary');
      el.parentNode.appendChild(spinner);
  } catch(e) {
    console.log('Failed to create spinner');
  }
}

/**
 * 
 */
function hideSpinner() {
  try {
    const spinners = Array.from(
      document.querySelectorAll('.spinner-border')
    );
    spinners.forEach(spinner => {
      spinner.parentNode.removeChild(spinner.parentNode.lastChild)
    });
  } catch(e) {
    console.log('Failed to remove spinner');
  }
}

/**
 * 
 * @param messages
 * @returns
 */
export async function groupConditionalMandatoryMessages(messages) {
  
  const messageList = messages.reduce((r, m) => {
    r[m.CASE_NO] = [...r[m.CASE_NO] || [], m];
    return r;
  }, {});
  console.log("messageList", messageList);
  
  let wrapper = document.createElement('DIV');

  const groups = Object.keys(messageList);
  for (const group of groups) {
    try {
      const condition = messageList[group][0].CM_AND_OR;
      let prefix, ul;
      switch (condition) {
        case "OR":
          prefix = document.body.pageConfig.cmOrErrorMsg;
          break;
        default:
          prefix = document.body.pageConfig.cmAndErrorMsg;
          break;
      }
      const prefixNode = document.createRange().createContextualFragment(`<span>${prefix}</span>`);
      wrapper.appendChild(prefixNode);
      ul = document.createElement('UL');
      messageList[group].forEach(function(msg){
        const li = document.createRange().createContextualFragment(`<li>${msg.COND_PID}</li>`);
        ul.appendChild(li);
      });
      
      wrapper.appendChild(ul);
    } catch(e) {
      console.error("Failed to process conditional mandatory message", e);
    }
  }
  
  return wrapper;
}

/**
 * 
 */
export async function showMessage(message, replace = true) {
  let msgModalBody = document.getElementById('msgModalBody');
  // console.log(`About to show message: ${message}, replace=${replace}`);
  if (replace) {
    // remove prior messages
    while (msgModalBody.firstChild) {
      msgModalBody.removeChild(msgModalBody.firstChild);
    }
  }
  try {  
    // add new message
    msgModalBody.appendChild(message);
    // msgModalBody.appendChild(document.createRange().createContextualFragment(message));
  } catch(e) {
    try {
      msgModalBody.appendChild(document.createRange().createContextualFragment(message));
    } catch (e1) {
      console.error("Failed to show message", e1);
    }
  }
  $('#msgModal').modal({'backdrop' :false});
}

/**
 * 
 */
function isElement($obj){
  try {
    return ($obj.constructor.__proto__.prototype.constructor.name)?true:false;
  }catch(e){
    return false;
  }
}

/*
 *
 */
export function parseExodusEvent(event) {
  const {par} = event;
  let longLinkList = [];
  if (par) {
    if (Array.isArray(par)) {
      par.forEach(p => {
        const l = parseLongLink(p);
        if (l) {
          longLinkList.push(l);
        }
      });
    } else {
      const l = parseLongLink(par);
        if (l) {
          longLinkList.push(l);
        }
    }
  }

  return longLinkList;
}

/*
 * {"paramId":pid[0],"paramValueId":pid[1],"ackReq":pid[2],"selected":pid[3],"strength":pid[4],"computeValue":pid[5]};
 */
function parseLongLink(ll) {
  let longLink = {};
  const llArray = ll.split('~');

  if (llArray.length >=5 ) {
    longLink.paramId = llArray[0];
    longLink.paramValueId = llArray[1];
    longLink.ackReq = llArray[2];
    longLink.selected = llArray[3];
    longLink.strength = llArray[4];
    longLink.computeValue = llArray[5];

    if(llArray.length > 6)  {
      longLink.displayValue = llArray[6];
    }
  }

  return longLink;
}