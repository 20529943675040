/**
 * Creates STD or FLOAT blocks. If type of block is FLOAT, the block will be
 * wrapped inside another div in order for the sticky property to work. <br>
 * bb = blockBody, bh = blockHeader, fullB = fullBlock, bWrap = blockWrapper
 * 
 * @class StdBlock
 * @param {object}
 *          block
 */

// import resolveWidth from './resolveWidth.js';
import { global as g } from '../../globals.js';

export default class StdBlock {// STD Block

  constructor(block, bFrag) {
    this.block = block;
    this.bFrag = bFrag;

    this.bh = this.buildBlockH();
    this.bb = this.buildBlockB();
    this.fullB = this.buildFullBlock();// a wrapper if block.type==FLOAT
    //this.fullB.dataset.blockType = this.block.type;

    if (this.block.hide === 1) {
      this.fullB.classList.add('invisible');
    } else if (this.block.hide === 2) {
      this.fullB.classList.add('d-none');
    }

    g.addTooltip(this.bh, this.block);

  }

  /** @memberof StdBlock# */
  buildBlockH() {
    // header.row>div.col.headerCssId
    const { id, label, headerCssId, initState } = this.block;
    const bh = document.createElement('header');
    
    
    if (initState !== 'E') {
      bh.classList.add('collapsed');
    }
    
    const bhc = document.createElement('div');

    bh.id = `blockh-${id}`;
    bh.classList.add('row');
    bhc.classList.add('col');

    if (label) {
      let labelContent; // blockHeaderRow, blockHeaderColumn
      labelContent = document.createElement('div');
      if (headerCssId) {
        labelContent.classList.add(headerCssId);
      }
      labelContent.appendChild(document.createRange().createContextualFragment(label));
// labelContent.innerHTML = label;
      bhc.appendChild(labelContent);
    }

    bh.appendChild(bhc);
    return bh;
  }

  /** @memberof StdBlock# */
  buildBlockB() {
    const { id, bodyCssId } = this.block;
    const bb = document.createElement('div');
    bb.id = `blockb-${id}`;

    if (bodyCssId)
      bb.classList.add(bodyCssId);

    return bb;
  }

  /** @memberof StdBlock# */
  buildFullBlock() {
    let { id, cssId, type, width, breakpoint, hide } = this.block;
    breakpoint = breakpoint===""?"md":breakpoint;

    // width = resolveWidth(width); // edn 20200923
    const colDiv = document.createElement('div');
    if (width !== 'NIL') {
      colDiv.classList.add(`col-${breakpoint}-${width}`);
    } else {
      colDiv.classList.add(`col-${breakpoint}`);
    }

    const fullB = document.createElement('section');
    const comment = document.createComment(`BLOCK ${id} starts on section right above.`);

    fullB.id = `block-${id}`;
    // fullB.setAttribute('name', `#block-${id}`);

    // classes: col then block-wrapper, then cssId, order matters

    fullB.appendChild(comment);
    fullB.appendChild(this.bh);
    fullB.appendChild(this.bb);

    // console.log(`Block ${id} type "${type}"`)

    if (type === 'FLOAT') {
      const wrapper = document.createElement('div');
      fullB.classList.add('sticky-top', 'block-wrapper');

      if (cssId) {
        fullB.classList.add(cssId);
      }

      if (width !== 'NIL') {
        wrapper.classList.add(`col-${breakpoint}-${width}`);
      } else {
        wrapper.classList.add(`col-${breakpoint}`);
      }
      wrapper.appendChild(fullB);
      return wrapper;
    }

    // else: block is STD
    fullB.classList.add('block-wrapper');

    fullB.dataset.blockHide = hide;
    fullB.dataset.blockType = type;

    if (cssId) {
      const _classes = cssId.split(" ");
      for (const _class of _classes) {
        fullB.classList.add(_class);
      }
    }

    colDiv.appendChild(fullB); //
    // return fullB;
    return colDiv;
  }

  /** @memberof StdBlock# */
  isCollapsable() {

    if (this.block.collapsable) {

      const bh = this.bh;
      const bb = this.bb;
      const fullB = this.fullB;
      const rowCol = bh.querySelector('.col');
      
      if (this.block.initState === 'O') {
        // toggle block in expanded state
        fullB.querySelector('section').classList.add('collapse', 'show');
      } else if (this.block.initState === 'T') {
        // toggle block
        fullB.querySelector('section').classList.add('collapse');
      } else {
        // If not a toggle block set controls in header
        bh.setAttribute('data-toggle', "collapse");
        bh.setAttribute('data-target', `#${bb.id}`);
        bh.setAttribute('aria-expanded', "false");
        bh.setAttribute('aria-controls', `${bb.id}`);
        bb.classList.add('collapse');
      }

      if (this.block.initState === 'E') {
        bb.classList.add('show');
      }
    }
  }

  appendBlock() {
    // console.log(this.block);
    const { parentLoc } = this.block;
    const bFrag = this.bFrag;

    this.isCollapsable();

    if (parentLoc) {
      // check for row in parent, if there is none, create it before appending
      // to it
      let parentRow = bFrag.querySelector(`#blockb-${parentLoc} > .row`);

      if (!parentRow) {
        parentRow = document.createElement('div');
        parentRow.classList.add('row');
        try {
          bFrag.querySelector(`#blockb-${parentLoc}`).appendChild(parentRow);
        } catch(e) {
          console.warn(`Failed to append parent row for #blockb-${parentLoc}`);
        }
      }

      parentRow.appendChild(this.fullB);
      g.isTopLvlContainer = true;
      
    } else {
      if (g.isTopLvlContainer) { // if no parent, and g.isTopLvlContainer
        const container = createContainer(this.fullB);
        bFrag.appendChild(container);
        g.isTopLvlContainer = false;

      } else {
        // console.error(`g.container: ${g.container}`);
        const containerRow = bFrag.querySelector(`.${g.container}`).lastChild;
        try {
          containerRow.appendChild(this.fullB);
        } catch (e) {
          console.error(`Failed to append block to the container row: ${e}`);
        }
      }
    }
  } // end appendBlock
}

function createContainer(fullBlock) {

  const container = document.createElement('div');
  const row = document.createElement('div');

  row.classList.add('row');
  container.classList.add(`${g.container}`);
  row.appendChild(fullBlock);
  container.appendChild(row);

  return container;
}
