/**
 * Creates Table blocks. <br>1290
 * bb = blockBody, bh = blockHeader, fullB = fullBlock, bWrap = blockWrapper
 * 
 * @class TblBlock
 * @extends StdBlock
 * @param {object}  block
 */

import StdBlock from './StdBlock.js';

export default class TblBlock extends StdBlock {

  constructor(block, bFrag) {
    super(block, bFrag);
    this.bb.appendChild(this.createTable());
    this.createRows();
  }

  createTable() {
    const { id, tblHover, tblStriped, tblBordered, tblScrollX, navCssId, hide } = this.block;

    const table = document.createElement('table');
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');

    table.id = 'tbl-' + id;
    table.setAttribute('width','100%');
    table.dataset.navCssId = navCssId;
    table.dataset.blockHide = hide;
    
    if (tblHover === 1) {
      table.classList.add('hover');
    }
    
    if (tblStriped === 1) {
      table.classList.add('stripe');
    }

    if (tblBordered === 1) {
      table.classList.add('row-border');
    }

    if (tblScrollX === 0) {
      table.classList.add('responsive');
    }
        
    table.appendChild(thead);
    table.appendChild(tbody);

    this.table = table;
    return table;
  }

  /*
   * create thead datatables, and datatables with html5 create header create
   * tbody create header cells initially next step will be styling
   */
  createRows() {
    const { numRows, numCols, id, cells } = this.block;

    if (cells instanceof Array) {
      cells.sort(function(a, b) {
        return (
          Number(a.cellId.split(',')[1]) - Number(b.cellId.split(',')[1]) ||
          Number(a.cellId.split(',')[2]) - Number(b.cellId.split(',')[2])
        );
      });
    } 

    let lastRowId, row;

    const table = this.table;
    const thead = this.table.querySelector('thead');
    const tbody = this.table.querySelector('tbody');

    cells.forEach(function(cell) {
      let col, cellId;

      // if width is defined for the cell then process it
      if (cell.widthFraction) {
        cellId = cell.cellId.split(',');

        // if first row, create ths and append to thead
        if (cellId[1] === '1') {
          if (row === undefined || cellId[1] !== lastRowId) {
            row = document.createElement('tr');
            lastRowId = cellId[1];
            thead.appendChild(row);
          }

          col = document.createElement('th');
          col.id = 'c-' + cellId.join('.');
          
          col.classList.add(cell.cssId);
          row.appendChild(col);
        } else {

          if (cellId[2] === '1' || cellId[1] != lastRowId) {
            row = document.createElement('tr');
            lastRowId = cellId[1];
            tbody.appendChild(row);
          }

          col = document.createElement('td');
          col.id = 'c-' + cellId.join('.');
          row.appendChild(col);
        }
      }
    });
  }

  // override method on prototype
  attachListeners() {
    const { id, tblSearch, tblPager, tblScrollX , numCols, cells } = this.block;
    const table = this.table;
    
    $(document).ready(function() {
      let columnDefs = [];
      const tableId = `#tbl-${id}`;
      // console.log(`Initializing DataTable ${tableId}`);
     
      // grab second row of cells as they contain cssId for data rows and width codes
      // width fraction can not have responsive trigger in it - just number
      for(let i = numCols, j = 0; i < numCols*2; i++, j++) {
        let w = cells[i].widthFraction;

        // console.log(`Width (1): ${w}`);

        // if fraction contains responsive trigger - chop it off
        if (w.indexOf('-') > -1) {
          w = w.substr(w.indexOf('-')+1);
        }

        // console.log(`Width (2): ${w}`);

        let columnDef = {
          targets: j,
          createdCell:  function (td, cellData, rowData, row, col) {$(td).addClass(cells[i].cssId)}
        };
        
        // the current column is hidden with either "none" or "never" class
        if(w!=='0') {
          columnDef.width = `${w*100/24}%`;
        } 
        // else {
        //   columnDef.visible = false;
        // }
        // console.log(`Col width (3): ${columnDef.width}`);
        columnDefs.push(columnDef);
      }

      // // add expand/collapse control to the last column
      // columnDefs.push({
      //   className: 'control',
      //   orderable: false,
      //   targets: -1
      // });

      // console.log('table is below: ');
      // console.log(table);

      let rowH = table.querySelector('thead tr:nth-child(1)');
      let row2 = table.querySelector('tbody tr:nth-child(2)');
      let row3 = table.querySelector('tbody tr:nth-child(3)');
      let row4 = table.querySelector('tbody tr:nth-child(4)');
      let dataOrder = [];

      // console.log("DT rows: ");
      // console.log(rowH);
      // console.log(row2);
      // console.log(row3);
      // console.log(row4);
      
      
      for(let i = 0; i < numCols; i++) {
        // add responsive classes defined on row 4 
        // to the corresponding column headers
        if (row4.childNodes[i].innerHTML.length > 0) {
          // let cls = row4.childNodes[i].innerHTML.startsWith('0')?"never":row4.childNodes[i].innerHTML.startsWith('1')?"always":row4.childNodes[i].innerHTML;
          // rowH.childNodes[i].classList.add(cls);
          rowH.childNodes[i].classList.add(row4.childNodes[i].innerHTML);
        }

        // buid data-order object to set it to the table data-order attribute
        // data-order="[[ 1, &quot;asc&quot; ]]"
        if (row3.childNodes[i].innerHTML.length > 0) {
          try {
            let order = parseInt(row3.childNodes[i].innerHTML);
            if (order > 0) {
              let direction = row2.childNodes[i].innerHTML;
              direction = direction.length>0?direction.toLowerCase():'asc';
              let colOrder = [i,direction];
              dataOrder.push(colOrder);
            }
          } catch(e) {
            console.log(`Failed to set sort order for datatable ${id}, reason: ${e}`);
          }
        }
      } 

      if (dataOrder.length>0) {
        table.dataset.order = JSON.stringify(dataOrder);
        // .replace(/"/g, "&quot;");
      }

      // remove all rows from tbody as we needed them for configuration only
      let tbody = table.querySelector('tbody');
      while (tbody.firstChild) {
        tbody.removeChild(tbody.firstChild);
      }

      // existing datatable can not be reinitialized. 
      // proceed only if datatable does not exist yet
      if (!$.fn.DataTable.isDataTable(tableId)) {
        const dtTable = $(tableId);
        // console.log(`About to init DataTable (2): ${tableId}`);
        dtTable.DataTable({
          columnDefs: columnDefs,
          searching: tblSearch===0?false:true,
          paging: tblPager===0?false:true,
          scrollX: tblScrollX===0?false:true,
          // dom: '<"top"f<"clear">>rt<"bottom"lip<"clear">>'
          dom: `<"top"f<"clear">>rt<"bottom row"<"dt-f-l col-sm-6"l><"dt-f-c col-sm-12"p><"dt-f-r col-sm-6"i><"clear">>`,
          initComplete: function (settings, json) {
            let _class = dtTable.data("navCssId");
            if (_class.length > 0) {
              $(`${tableId}_length`).addClass(`${_class}`);
              $(`${tableId}_paginate`).addClass(`${_class}`);
              $(`${tableId}_info`).addClass(`${_class}`);
            }
          }
        });
      } 

      // else {
      //   console.log(`DataTable ${tableId} already exists!`);
      // }
    });
  }
}
