/**
 * @class CardBlock
 */
// import { global as g } from '../../globals.js';
import StdBlock from './StdBlock';

export default class FlipCardBlock extends StdBlock {

    constructor(block, bFrag) {
        super(block, bFrag);
    }

    /**
     * 
     */
    buildBlockH() {
        const { id, label, headerCssId, initState } = this.block;
        // const bh = document.createElement('header');
        // bh.id = `blockh-${id}`;
        // // bh.classList.add('card-title');

        // if (label) {
        //     let labelContent; // blockHeaderRow, blockHeaderColumn
        //     labelContent = document.createElement('div');
        //     if (headerCssId) {
        //         labelContent.classList.add(headerCssId);
        //     }
        //     labelContent.appendChild(document.createRange().createContextualFragment(label));
        //     // labelContent.innerHTML = label;
        //     bh.appendChild(labelContent);
        // }
        const bh = document.createComment(`BLOCK ${id} header`);
        return bh;
    }

    /** @memberof StdBlock# */
    buildBlockB() {
        const { id, bodyCssId } = this.block;
        const bb = document.createElement('div');
        
        bb.id = `blockb-${id}`;
        bb.classList.add('flip');
        // if (bodyCssId) {
        //     bb.classList.add(bodyCssId);
        // }
        return bb;
    }

    /** @memberof StdBlock# */
    buildFullBlock() {
        const { id, cssId, type, width, breakpoint, hide } = this.block;
        const colDiv = document.createElement('div');

        if (width !== 'NIL') {
            // colDiv.classList.add(`col-${breakpoint}-${width}`,'mb-1');
            colDiv.classList.add(`col-${breakpoint}-${width}`);
        } else {
            // colDiv.classList.add(`col-${breakpoint}`,'mb-1');
            colDiv.classList.add(`col-${breakpoint}`);
        }

        const fullB = document.createElement('section');
        const comment = document.createComment(`BLOCK ${id} starts on section right above.`);
        fullB.id = `block-${id}`;
        // fullB.classList.add('card', 'h-100', 'mb-1');
        fullB.classList.add('card', 'h-100');
        
        fullB.appendChild(comment);
        fullB.appendChild(this.bh);
        fullB.appendChild(this.bb);

        // else: block is STD
        fullB.classList.add('block-wrapper');
        fullB.dataset.blockHide = hide;
        fullB.dataset.blockType = type;

        // if (cssId) {
        //     fullB.classList.add(cssId);
        // }

        colDiv.appendChild(fullB);

        return colDiv;
    }

    /**
     * 
     */
    appendBlock() {
        // console.log(this.block);
        const { parentLoc } = this.block;
        const bFrag = this.bFrag;
    
        this.isCollapsable();
    
        if (parentLoc) {
            // check for row in parent, if there is none, create it before appending
            // to it
            let parentRow = bFrag.querySelector(`#blockb-${parentLoc} > .row`);
    
            if (!parentRow) {
                parentRow = document.createElement('div');
                parentRow.classList.add('row');
                try {
                    bFrag.querySelector(`#blockb-${parentLoc}`).appendChild(parentRow);
                } catch(e) {
                    console.warn(`Failed to append parent row div for #blockb-${parentLoc}`);
                }
            }
    
            parentRow.appendChild(this.fullB);
        }
         // end appendBlock
    }
    
}