import sendRequest, {PAGE_OPEN_URL} from './modules/createRequest.fetch.js';
import buildPage from './modules/buildPage.js';
import { global as g } from './globals.js';
import {showMessage} from './modules/utils.js';

const openPage = async (params = '') => {
 
  try {
    // need to append page name to event handler
    // in order to clear splash page
    let path = window.location.pathname;
    let resource = path.substring(path.lastIndexOf('/')+1);
    let eventHandlerUrl = PAGE_OPEN_URL;
    // console.log(path); // get current path, for ex: '/index.php', '/docs/pdf/detail.php'
    // console.log(resource); // for ex: '/index.php'=>'/', '/docs/pdf/detail.php'=>'/docs/pdf/'
    if (resource.trim().length > 0) {
      eventHandlerUrl = `${PAGE_OPEN_URL}/pn/${resource}`;
    }
    
    // console.log(params);
    const {status,statusText,data} = await sendRequest(eventHandlerUrl, params);
    // set flag to the default value in case we are loading page
    // into existing container (page) 20201222
    g.isTopLvlContainer = true;
    // define list for the mandatory pids. will populate later.
    document.body.mandatoryPids = [];
    // we are loading a new page - remove active modals
    // from the current page
    $(".modal").modal("hide");
    $(".modal-backdrop.in").remove();
    
    if (data.page != null) {
      document.body.pageConfig = data.page;
      updatePageTitle(data.page);
      const bFrag = initExoContainer(data);
      buildPage(data, bFrag);
    }

    // if (data.pageMessageList.length > 0) {
    data.pageMessageList.forEach((msg, i) => {
      showMessage(msg.MESSAGE, (i===0));
      if (data.page == null) {
        setTimeout(() => {  window.location.href = "/home"; }, 5000);
      }
    });
    
  } catch (err) {
    console.error(err);
  }
}


function initExoContainer(data) {

  const bFrag = document.createDocumentFragment();
  const exoContainer = document.querySelector(`.${g.exoContainer}`);

  while (exoContainer.firstChild) {
    exoContainer.removeChild(exoContainer.firstChild);
  }
  
  exoContainer.dataset.pageId = data.pageId;
  exoContainer.dataset.pvsId = data.pageViewSeqId;
  exoContainer.dataset.myPageId = data.pageId;
  if (data.bom) {
    exoContainer.dataset.bomId = data.bom.BOM_ID;
    exoContainer.dataset.bomName = data.bom.BOM_NAME;
  }
  exoContainer.dataset.animation = isAnimationOn();

  // this print is for data team
  console.info(`Page Open: pageId=${data.pageId}, pvsid=${data.pageViewSeqId}`);
  return bFrag;
}

function updatePageTitle(pageConfig) {
	// possibly add validation
  try {
    document.title = pageConfig.pageName;
  } catch (e) {}
}

function isAnimationOn() {
  let url = new URL(window.location.href);
  let anime = url.searchParams.get("animation");
  if (anime) {
    return anime;
  } else {
    return 1;
  }
}

export default openPage;
