/**
 * Creates Table for Child Pages blocks. <br>
 * bb = blockBody, bh = blockHeader, fullB = fullBlock, bWrap = blockWrapper
 * 
 * @class TblBlock
 * @extends StdBlock
 * @param {object}
 *          block
 */

// import resolveWidth from './resolveWidth.js';
import { global as g } from '../../globals.js';
import StdBlock from './StdBlock.js';

export default class TblChildBlock extends StdBlock {
  // STD Block

  constructor(block, bFrag) {
    super(block, bFrag);
    this.bb.appendChild(this.createTable());
    this.createRows();
  }

  createTable() {
    const { id } = this.block;

    const table = document.createElement('table');
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');

    table.id = 'tblchild-' + id;
    table.classList.add('table', 'table-sm');
    table.appendChild(thead);
    table.appendChild(tbody);

    this.table = table;
    return table;
  }

  /*
   * create thead datatables, and datatables with html5 create header create
   * tbody create header cells initially next step will be styling
   */
  createRows() {
    const { numRows, numCols, id, cells } = this.block;
    let lastRowId, row;
    const thead = this.table.querySelector('thead');
    const tbody = this.table.querySelector('tbody');

    cells.forEach(function(cell) {
      let col, cellId;

      // if width is defined for the cell then process it
      if (cell.widthFraction) {
        cellId = cell.cellId.split(',');

        // if first row, create ths and append to thead
        if (cellId[1] === '1') {
          if (row === undefined || cellId[1] !== lastRowId) {
            row = document.createElement('tr');
            // row.className = 'row';
            lastRowId = cellId[1];
            thead.appendChild(row);
          }

          col = document.createElement('th');
          col.id = 'c-' + cellId.join('.');
          if (cell.cssid) {
            col.className = cell.cssid;
          }
          row.appendChild(col);
        } else {
          if (row === undefined || cellId[1] !== lastRowId) {
            row = document.createElement('tr');
            row.classList.add('d-none');
            // row.className = 'row';
            lastRowId = cellId[1];
            tbody.appendChild(row);
          }

          col = document.createElement('td');
          col.id = 'c-' + cellId.join('.');
          if (cell.cssid) {
            col.className = cell.cssid;
          }
          row.appendChild(col);
        }
      }
    });
  }

  // override method on prototype
  attachListeners() {
    const { id } = this.block;
    
    // need to attach onclick listeners for child pages here

    if (this.block.collapsable) {
      const bb = this.bb;
      const img = this.bh.querySelector('span > img');

      $(`#${bb.id}`).on('shown.bs.collapse', function() {
        img.src = `${g.baseUrl}/resources/img/minus.png`;
      });

      $(`#${bb.id}`).on('hidden.bs.collapse', function() {
        img.src = `${g.baseUrl}/resources/img/plus.png`;
      });
    }
  }
}
