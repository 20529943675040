import { updatePvpState } from "./deltaChangeHandler.js";
import { format } from "./formatters.js";
/**
 * Adds control type on given targetCell
 *
 * @module addControlType
 *
 * @param {object[]}
 *          par - Array of parameters
 * @param {object}
 *          targetCell - div.col...
 */
export default function addControlType(par, targetCell) {
  if (par.mandatory === 1) {
    document.body.mandatoryPids.push(par.id);
  }

  switch (par.controlType) {
    // S selectors
    case "CheckBoxOne":
      createCheckboxOne(par, targetCell);
      break;
    case "CheckBoxMany":
      createCheckboxMany(par, targetCell);
      break;
    case "PullDown":
      createPullDown(par, targetCell);
      break;
    case "CheckBoxCustom":
      createCustomS(par, targetCell);
      break;
    // IO selectors
    case "InputBox":
      createInputBox(par, targetCell);
      break;
    case "Image":
      createImage(par, targetCell);
      break;
    case "Button":
      createButton(par, targetCell);
      break;
    case "OutputBox":
      createOutputBox(par, targetCell);
      break;
    case "OutputBoxS":
      createOutputBoxS(par, targetCell);
      break;
    case "Slider":
      createSlider(par, targetCell);
      break;
    case "Chart":
      createChart(par, targetCell);
      break;
    case "MetaIO":
    case "MetaS":
      createMetaTag(par, targetCell);
      break;
    case "VlookupUpload":
      createVlookupUploadForm(par, targetCell);
      break;
    case "Meter":
      createMeter(par, targetCell);
      break;
    default:
      if (par.controlType.indexOf("TextArea") >= 0) {
        // ta[1] = number of visible rows
        let ta = par.controlType.split("-");
        createTextArea(par, targetCell, ta[1]);
      } else if (par.controlType.indexOf("Chart") >= 0) {
        // ch[1] = chart width, ch[2] = chart height
        let ch = par.controlType.split("-");
        createChart(par, targetCell, ch[1]);
      } else if (par.controlType.indexOf("CheckBoxCustom") >= 0) {
        // cs[1] = responsive trigger, cs[2] = number of columns
        let cs = par.controlType.split("-");
        createGridCustomS(par, targetCell, cs[1], cs[2]);
      } else if (par.controlType.indexOf("Slider") >= 0) {
        // s[1] - min val, s[2] - max val, s[3] - step
        let s = par.controlType.split("-");
        createSlider(par, targetCell, s[1], s[2], s[3]);
      } else {
        console.error(`Unrecognized control type=${par.controlType}`);
      }
  }
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createPullDown(par, targetCell) {
  let sel, opt, txt;
  sel = document.createElement("select");
  sel.className = "form-control form-control-sm";
  if (par.cssId && par.cssId !== "NIL") {
    sel.classList.add(par.cssId);
    // enable live search on dropdowns
    if (par.cssId === "searchable") {
      sel.dataset.liveSearch = "true";
      sel.dataset.container = "body";
      // sel.dataset.hideDisabled="true";
    }
  }
  sel.dataset.ack = par.ackReq;
  sel.dataset.lastsel = "0";
  sel.id = par.id;
  // convert id in just number and set it as tabindex
  sel.setAttribute("tabindex", targetCell.id.replace(/^\D+|\./g, ""));
  targetCell.appendChild(sel);
  opt = document.createElement("option");
  txt = document.createTextNode(par.pullDownClearText);
  opt.appendChild(txt);
  sel.appendChild(opt);

  par.pvps.sort(function (a, b) {
    return a.order - b.order;
  });

  par.pvps.forEach(function (pvp, idx) {
    opt = document.createElement("option");
    opt.id = pvp.id;
    opt.name = par.id;
    opt.dataset.pid = par.id;
    opt.dataset.ack = par.ackReq;
    opt.dataset.state = pvp.state;
    opt.dataset.dt = pvp.disabledType;
    opt.value = pvp.dvalue;
    txt = document.createTextNode(pvp.dvalue);
    opt.appendChild(txt);
    sel.appendChild(opt);
    updatePvpState(pvp, opt);
    if (pvp.state === 4) {
      sel.dataset.lastsel = idx + 1;
    }
  });
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createInputBox(par, targetCell) {
  const inputBox = document.createElement("input");
  inputBox.dataset.pid = par.id;
  inputBox.dataset.ack = par.ackReq;
  inputBox.placeholder = par.pullDownClearText;
  // convert id in just number and set it as tabindex
  inputBox.setAttribute("tabindex", targetCell.id.replace(/^\D+|\./g, ""));
  // inputBox.setAttribute('autocomplete','off');
  inputBox.setAttribute("autocomplete", "randomText");
  if (par.minValue !== "NIL") {
    inputBox.dataset.minv = par.minValue;
  }

  if (par.maxValue !== "NIL") {
    inputBox.dataset.maxv = par.maxValue;
  }

  if (par.minLength !== "NIL") {
    inputBox.dataset.minl = par.minLength;
  }

  if (par.maxLength !== "NIL") {
    inputBox.dataset.maxl = par.maxLength;
  }

  if (par.expression !== "NIL") {
    inputBox.dataset.expr = par.expression;
  }

  if (par.expFailureMsg !== "NIL") {
    inputBox.dataset.expfailmsg = par.expFailureMsg;
  }

  try {
    inputBox.id = par.pvps[0].id;
    inputBox.className = "form-control form-control-sm";
    targetCell.appendChild(inputBox);
    inputBox.dataset.dt = par.pvps[0].disabledType;

    if (par.cssId) {
      const cls = par.cssId.split(" ");
      cls.forEach((cl) => {
        if (cl.length > 0 && cl !== "NIL") {
          inputBox.classList.add(cl);
        }
      });
    }

    if (par.pvps[0].dvalue.length > 0) {
      let value;
      if (par.dataType === "Number") {
        value = format(inputBox, par.pvps[0].dvalue);
      } else {
        value = par.pvps[0].dvalue;
      }
      inputBox.value = value;
    }

    updatePvpState(par.pvps[0], inputBox);
  } catch (err) {
    console.error(par);
    console.error(`Failed to render input for pid=[${par.id}], reason: ${err}`);
  }
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createOutputBox(par, targetCell) {
  const outputBox = document.createElement("div");
  outputBox.dataset.pid = par.id;
  outputBox.dataset.type = par.dataType;
  try {
    outputBox.id = par.pvps[0].id;
    // outputBox.classList.add('form-control-sm');
    if (par.cssId) {
      const cls = par.cssId.split(" ");
      cls.forEach((cl) => {
        if (cl.length > 0 && cl !== "NIL") {
          outputBox.classList.add(cl);
        }
      });
    }
    targetCell.appendChild(outputBox);
    outputBox.dataset.dt = par.pvps[0].disabledType;
    if (par.pvps[0].dvalue.length > 0) {
      let value;
      if (par.dataType === "Number") {
        value = format(outputBox, par.pvps[0].dvalue);
      } else {
        value = par.pvps[0].dvalue;
      }
      let fragment = document.createRange().createContextualFragment(value);
      outputBox.appendChild(fragment);
    }

    updatePvpState(par.pvps[0], outputBox);
  } catch (err) {
    console.error(
      `Failed to render output box for pid=${par.id}, reason: ${err}`
    );
  }
}

/**
 *
 */
function createOutputBoxS(par, targetCell) {
  let pidNode = document.createElement("ul"),
    tabIdx,
    pvpNode,
    outputS,
    cssIds = [];
  pidNode.dataset.ack = par.ackReq;
  pidNode.id = par.id;
  pidNode.classList.add("form-control-sm", "output-s", "h-100");

  if (par.cssId) {
    const cls = par.cssId.split(" ");
    cls.forEach((cl) => {
      if (cl.length > 0 && cl !== "NIL") {
        pidNode.classList.add(cl);
      }
    });
  }

  targetCell.appendChild(pidNode);

  par.pvps.sort(function (a, b) {
    return a.order - b.order;
  });

  par.pvps.forEach(function (pvp) {
    pvpNode = document.createElement("li");
    pvpNode.classList.add("output-s-item");

    let value;
    if (par.dataType === "Number" && !isNaN(Number(pvp.dvalue))) {
      value = format(pidNode, pvp.dvalue);
    } else {
      value = pvp.dvalue;
    }

    outputS = document.createRange().createContextualFragment(value);
    pvpNode.appendChild(outputS);

    pvpNode.dataset.pid = par.id;
    pvpNode.dataset.ack = par.ackReq;
    pvpNode.dataset.state = pvp.state;
    pvpNode.dataset.dt = pvp.disabledType;
    pvpNode.id = pvp.id;
    updatePvpState(pvp, pvpNode);
    pidNode.appendChild(pvpNode);
  });

  let show =
    pidNode.querySelectorAll('[data-state="4"] ').length +
    pidNode.querySelectorAll('[data-state="2"]').length;
  if (show !== 1) {
    pidNode.classList.add("d-none");
  }
}

/**
 *
 * @param par
 * @param targetCell
 * @param nOfRows
 * @returns
 */
function createTextArea(par, targetCell, nOfRows) {
  const textArea = document.createElement("textarea");
  textArea.dataset.pid = par.id;
  textArea.dataset.ack = par.ackReq;
  try {
    textArea.placeholder = par.pullDownClearText;
  } catch (err) {}
  // convert id in just number and set it as tabindex
  textArea.setAttribute("tabindex", targetCell.id.replace(/^\D+|\./g, ""));
  textArea.setAttribute("rows", nOfRows);

  if (par.minValue !== "NIL") {
    textArea.dataset.minv = par.minValue;
  }

  if (par.maxValue !== "NIL") {
    textArea.dataset.maxv = par.maxValue;
  }

  if (par.minLength !== "NIL") {
    textArea.dataset.minl = par.minLength;
  }

  if (par.maxLength !== "NIL") {
    textArea.dataset.maxl = par.maxLength;
  }

  if (par.expression !== "NIL") {
    textArea.dataset.expr = par.expression;
  }

  if (par.expFailureMsg !== "NIL") {
    textArea.dataset.expfailmsg = par.expFailureMsg;
  }

  try {
    textArea.id = par.pvps[0].id;
    if (par.cssId) {
      textArea.classList.add(par.cssId);
    }
    textArea.className = "form-control form-control-sm";
    targetCell.appendChild(textArea);
    // isPvpDisabled(textArea, par.pvps[0]);
    textArea.dataset.dt = par.pvps[0].disabledType;
    textArea.value = par.pvps[0].dvalue;
    // textArea.dataset.state = par.pvps[0].state;
    updatePvpState(par.pvps[0], textArea);
  } catch (err) {
    console.error(
      `Failed to render textArea for pid=${par.id}, reason: ${err}`
    );
  }
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createImage(par, targetCell) {
  const img = document.createElement("img");
  img.dataset.pid = par.id;
  img.dataset.ack = par.ackReq;

  try {
    img.id = par.pvps[0].id;
    img.className = "img-fluid";
    targetCell.appendChild(img);
    img.dataset.dt = par.pvps[0].disabledType;
    img.src = par.pvps[0].dvalue;

    // img.dataset.state = par.pvps[0].state;
    updatePvpState(par.pvps[0], img);
  } catch (err) {
    console.error(`Failed to render img for pid=${par.id}, reason: ${err}`);
  }
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createCheckboxOne(par, targetCell) {
  let cb,
    label,
    labelTxt,
    divCb,
    fieldset = document.createElement("fieldset"),
    tabIdx;
  fieldset.dataset.ack = par.ackReq;
  fieldset.id = par.id;
  fieldset.style.border = "none";

  if (par.cssId) {
    const cls = par.cssId.split(" ");
    cls.forEach((cl) => {
      if (cl.length > 0 && cl !== "NIL") {
        fieldset.classList.add(cl);
      }
    });
  }

  targetCell.appendChild(fieldset);
  // convert id in just number and set it as tabindex
  tabIdx = targetCell.id.replace(/^\D+|\./g, "");

  par.pvps.sort(function (a, b) {
    return a.order - b.order;
  });

  par.pvps.forEach(function (pvp) {
    divCb = document.createElement("div");
    label = document.createElement("label");
    cb = document.createElement("input");
    divCb.appendChild(cb);
    divCb.appendChild(label);

    cb.type = "checkbox";
    cb.dataset.pid = par.id;
    cb.dataset.ack = par.ackReq;
    cb.dataset.state = pvp.state;
    cb.dataset.dt = pvp.disabledType;
    cb.id = pvp.id;
    cb.name = par.id;
    cb.value = pvp.dvalue;
    cb.classList.add("form-check-input");
    cb.setAttribute("tabindex", tabIdx);

    label.classList.add("form-check-label");
    labelTxt = document.createRange().createContextualFragment(pvp.dvalue);
    label.appendChild(labelTxt);
    label.setAttribute("for", cb.id);

    if (pvp.disabledType === 3) {
      divCb.classList.add("d-none");
    } else {
      divCb.classList.add("form-check");
      switch (par.orientation) {
        case "h":
          divCb.classList.add("form-check-inline");
          label.classList.add("checkbox-inline");
          break;
        default:
          break;
      }
      divCb.classList.add("form-control-sm");
    }

    updatePvpState(pvp, cb);
    fieldset.appendChild(divCb);
  });
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createCheckboxMany(par, targetCell) {
  let cb,
    label,
    labelTxt,
    divCb,
    divWr,
    divSelected = document.createElement("div"),
    fieldset = document.createElement("fieldset"),
    ddDiv = document.createElement("div"),
    ddForm = document.createElement("form"),
    ddClose,
    ddButton,
    tabIdx;

  // dropdown div
  ddDiv.id = `dd-${par.id}`;
  ddDiv.classList.add('dropdown');

  // div to show selections
  divSelected.id = `dd-selected-${par.id}`;
  divSelected.classList.add('select-many-checked');

  // dropdown trigger button
  ddButton = document.createRange().createContextualFragment(
    `<button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" id="dd-${par.id}-trigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">None selected</button>`
  );
  ddDiv.appendChild(ddButton);

  // dropdown form
  ddForm.classList.add("dropdown-menu");
  ddForm.classList.add("p-4");
  ddForm.style.whiteSpace = 'nowrap';
  ddForm.style.height = 'auto';
  ddForm.style.maxHeight = '400px';
  ddForm.style.overflowX = 'hidden';
  ddForm.setAttribute("aria-labelledby", `dd-${par.id}-trigger`);
  ddDiv.appendChild(ddForm);

  ddDiv.appendChild(divSelected);

  ddClose = document.createRange().createContextualFragment(
    `<div id="dd-${par.id}-trigger2" style="text-align:right;cursor: pointer;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">&#x2715</div>`
  );
  ddForm.appendChild(ddClose);
  
  fieldset.dataset.ack = par.ackReq;
  fieldset.id = par.id;
  fieldset.style.border = "none";
  fieldset.classList.add('select-many-commit');

  if (par.cssId) {
    const cls = par.cssId.split(" ");
    cls.forEach((cl) => {
      if (cl.length > 0 && cl !== "NIL") {
        fieldset.classList.add(cl);
      }
    });
  }

  // fieldset.appendChild(divCb);
  ddForm.appendChild(fieldset);
  targetCell.appendChild(ddDiv);

  // convert id in just number and set it as tabindex
  tabIdx = targetCell.id.replace(/^\D+|\./g, "");

  par.pvps.sort(function (a, b) {
    return a.order - b.order;
  });

  par.pvps.forEach(function (pvp) {
    divWr = document.createElement("div");
    divWr.classList.add("form-group");

    divCb = document.createElement("div");
    label = document.createElement("label");
    cb = document.createElement("input");
    divCb.appendChild(cb);
    divCb.appendChild(label);

    cb.type = "checkbox";
    cb.dataset.pid = par.id;
    cb.dataset.ack = par.ackReq;
    cb.dataset.state = pvp.state;
    cb.dataset.dt = pvp.disabledType;
    cb.id = pvp.id;
    cb.name = par.id;
    cb.value = pvp.dvalue;
    cb.classList.add("form-check-input");
    cb.classList.add("select-many");
    cb.setAttribute("tabindex", tabIdx);

    label.classList.add("form-check-label");
    labelTxt = document.createRange().createContextualFragment(pvp.dvalue);
    label.appendChild(labelTxt);
    label.setAttribute("for", cb.id);

    if (pvp.disabledType === 3) {
      divCb.classList.add("d-none");
    } else {
      divCb.classList.add("form-check");
    }

    updatePvpState(pvp, cb);
    divWr.appendChild(divCb);
    fieldset.appendChild(divWr);
  });
}

/**
 *
 * @param par
 * @param targetCell
 * @param trigger
 * @param width
 * @returns
 */
function createCustomS(par, targetCell) {
  let divPid = document.createElement("div"),
    tabIdx,
    pvpNode,
    customS;
  divPid.dataset.ack = par.ackReq;
  divPid.id = par.id;
  targetCell.appendChild(divPid);
  // convert id in just number and set it as tabindex
  tabIdx = targetCell.id.replace(/^\D+|\./g, "");

  par.pvps.sort(function (a, b) {
    return a.order - b.order;
  });

  par.pvps.forEach(function (pvp) {
    pvpNode = document.createElement("a");
    switch (par.orientation) {
      case "h":
        // pvpNode = document.createElement('span');
        pvpNode.classList.add("d-inline-block");
        break;
      default:
        // pvpNode = document.createElement('div');
        pvpNode.classList.add("d-block");
        break;
    }

    pvpNode.classList.add("custom-s");
    customS = document.createRange().createContextualFragment(pvp.dvalue);
    pvpNode.appendChild(customS);

    pvpNode.dataset.pid = par.id;
    pvpNode.dataset.ack = par.ackReq;
    pvpNode.dataset.state = pvp.state;
    pvpNode.dataset.dt = pvp.disabledType;
    pvpNode.id = pvp.id;
    pvpNode.setAttribute("tabindex", tabIdx);
    updatePvpState(pvp, pvpNode);
    divPid.appendChild(pvpNode);
  });
}

/**
 *
 * @param par
 * @param targetCell
 * @param trigger
 * @param width
 * @returns
 */
function createGridCustomS(par, targetCell, trigger = "md", width = 24) {
  let divPid = document.createElement("div"),
    tabIdx,
    divPvp,
    customS;
  const col = trigger === "xs" ? `col-${width}` : `col-${trigger}-${width}`;

  divPid.dataset.ack = par.ackReq;
  divPid.id = par.id;
  divPid.classList.add("row");
  targetCell.appendChild(divPid);
  // convert id in just number and set it as tabindex
  tabIdx = targetCell.id.replace(/^\D+|\./g, "");

  par.pvps.sort(function (a, b) {
    return a.order - b.order;
  });

  par.pvps.forEach(function (pvp) {
    // divPvp = document.createElement('section');
    divPvp = document.createElement("a");
    // divPvp = document.createElement('button');
    // divPvp.setAttribute('type','button');
    // divPvp.classList.add('btn','btn-primary','btn-sm');

    divPvp.classList.add(col, "custom-s");

    customS = document.createRange().createContextualFragment(pvp.dvalue);
    divPvp.appendChild(customS);

    divPvp.dataset.pid = par.id;
    divPvp.dataset.ack = par.ackReq;
    divPvp.dataset.state = pvp.state;
    divPvp.dataset.dt = pvp.disabledType;
    divPvp.id = pvp.id;
    divPvp.setAttribute("tabindex", tabIdx);
    updatePvpState(pvp, divPvp);
    divPid.appendChild(divPvp);
  });
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createButton(par, targetCell) {
  const btn = document.createElement("button");
  btn.type = "button";
  btn.classList.add("btn");
  try {
    btn.id = par.pvps[0].id;
    if (par.cssId) {
      const cls = par.cssId.split(" ");
      cls.forEach((cl) => {
        if (cl.length > 0 && cl !== "NIL") {
          btn.classList.add(cl);
        }
      });
    }

    btn.dataset.pid = par.id;
    btn.dataset.dt = par.pvps[0].disabledType;
    btn.dataset.role = "button";
    // action for the button
    if (par.pvps[0].dvalue.length > 0) {
      const c = par.pvps[0].dvalue.split("|");
      // console.log(c);
      btn.appendChild(document.createRange().createContextualFragment(c[0]));

      if (Boolean(c[1])) {
        btn.dataset.event = c[1];
      } else {
        btn.classList.add("disabled");
      }
    } else {
      btn.classList.add("d-none");
    }

    targetCell.appendChild(btn);
    updatePvpState(par.pvps[0], btn);
  } catch (err) {
    console.error(`Failed to render button for pid=${par.id}, reason:
    ${err}`);
  }
}

function createVlookupUploadForm(par, targetCell) {
  const csrf = document.head.querySelector("[name='_csrf'][content]").content;
  const form = document.createRange().createContextualFragment(
    `<form class="uvl"  method="post" enctype="multipart/form-data" id="${par.id}-form" target="/uvl?_csrf=${csrf}">
      <input id="${par.pvps[0].id}" data-pid="${par.id}" type="hidden" name="${par.pvps[0].id}" value="${par.pvps[0].dvalue}" data-return-pid="1" data-ack="${par.ackReq}">
      <input type="file" name="file">
      <input class="btn btn-primary file-upload" type="submit" value="${par.pullDownClearText}" name="submit">
    </form>`
  );

  targetCell.appendChild(form);
  console.log(`#${par.pvps[0].id}`);
  updatePvpState(par.pvps[0], targetCell.querySelector(`#${par.pvps[0].id}`));
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createSliderOld(par, targetCell) {
  let min = par.minValue !== "NIL" ? par.minValue : 0,
    max = par.maxValue !== "NIL" ? par.maxValue : 100,
    step = 1;
  createSlider(par, targetCell, min, max, step);
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createSlider(par, targetCell, min = 0, max = 0, step = 0) {
  const input = document.createElement("input");
  const output = document.createElement("span");
  input.type = "range";
  input.dataset.pid = par.id;
  input.dataset.ack = par.ackReq;

  // convert id in just number and set it as tabindex
  input.setAttribute("tabindex", targetCell.id.replace(/^\D+|\./g, ""));
  input.min = min;
  input.max = max;
  input.step = step;
  try {
    input.id = par.pvps[0].id;
    input.classList.add("custom-range");
    // input.classList.add('custom-range','form-control');
    targetCell.appendChild(input);
    input.dataset.dt = par.pvps[0].disabledType;

    // if (par.cssId) {
    // inputBox.classList.add(par.cssId);
    // }

    if (par.cssId) {
      const cls = par.cssId.split(" ");
      cls.forEach((cl) => {
        if (cl.length > 0) {
          input.classList.add(cl);
        }
      });
    }

    if (par.pvps[0].dvalue.length > 0) {
      if (par.dataType === "Number") {
        input.value = par.pvps[0].dvalue;
      } else {
        // should never end up here
        input.value = min;
      }
    }

    output.classList.add("range-out");
    output.innerHTML = input.value;
    input.parentNode.appendChild(output);
    updatePvpState(par.pvps[0], input);
  } catch (err) {
    console.error(par);
    console.error(
      `Failed to render input.range for pid=[${par.id}], reason: ${err}`
    );
  }
}

/**
 *
 * @param par
 * @param targetCell
 * @returns
 */
function createChart(par, targetCell, heightPct = 100) {
  const canvas = document.createElement("canvas"),
    card = document.createElement("div"),
    cardBody = document.createElement("div"),
    pvp = par.pvps[0],
    aspectRatio = 100 / heightPct;
  card.classList.add("card", "card-chart");
  cardBody.classList.add("card-body");

  cardBody.appendChild(canvas);
  card.appendChild(cardBody);

  canvas.id = pvp.id;
  canvas.dataset.pid = par.id;
  canvas.classList.add("ct-chart", "exo-chart");
  canvas.dataset.pid = par.id;
  canvas.dataset.ack = par.ackReq;
  canvas.dataset.state = pvp.state;
  canvas.dataset.dt = pvp.disabledType;

  if (par.cssId) {
    const cls = par.cssId.split(" ");
    cls.forEach((cl) => {
      if (cl.length > 0) {
        canvas.classList.add(cl);
      }
    });
  }

  if (par.pvps[0].dvalue.length > 0) {
    // console.log("addControlType.js");
    // console.log(par.pvps[0].dvalue);
    
    // regex to extract all groups of chart config surrounded by square brackets
    const regex0 = /\[([^\[^\]]+),?\]/g;
    let match = regex0.exec(par.pvps[0].dvalue);
    let chartDef = '';
    
    while (match !== null) {
      if (match[1].trim().length <= 1) {
        continue;
      }
      chartDef = match[1].trim();
      break;
    }

    // Regex to extract all high level chart information
    const regex = /([\w]+):([^:|.]+)/g;
    let type = null;
    let legend = null;
    let tableName = null;
    let newChart = false;

    while ((match = regex.exec(chartDef)) !== null) {
      for (let i = 1; i < match.length; i += 2) {
        if (match[i] === "type") {
          type = match[i + 1];
          // canvas.classList.add(type);
        }
        if (match[i] === "legend") legend = match[i + 1];
        if (match[i] === "tablename") {
          tableName = match[i + 1];
          newChart = true;
        }
      }
    }

    if (newChart) {
      canvas.dataset.chartConfig = par.pvps[0].dvalue;
      // preserve all initial chart settings
      canvas.dataset.series = `[${chartDef}]`;
      // console.log(`New chart data-series=${canvas.dataset.series}`);
      canvas.classList.add(tableName);
    } else {
      // console.log("Old chart");
      canvas.dataset.series = par.pvps[0].dvalue;
    }
  } else {
    canvas.dataset.series = "";
  }

  canvas.dataset.aratio = aspectRatio;
  targetCell.appendChild(card);
}

/**
 * 
 * @param {*} par 
 * @param {*} targetCell 
 */
function createMeter(par, targetCell) {
  const meter = document.createElement("meter");
  meter.dataset.pid = par.id;

  try {
    meter.id = par.pvps[0].id;
    // value:min:max:low:high:optimum
    const meterAttrs = par.pvps[0].dvalue.split(':');
    if (meterAttrs[0]!=='') {
      meter.setAttribute('value',meterAttrs[0]);
    }
    if (meterAttrs[1]!=='') {
      meter.setAttribute('min',meterAttrs[1]);
    }
    if (meterAttrs[2]!=='') {
      meter.setAttribute('max',meterAttrs[2]);
    }
    if (meterAttrs[3]!=='') {
      meter.setAttribute('low',meterAttrs[3]);
    }
    if (meterAttrs[4]!=='') {
      meter.setAttribute('high',meterAttrs[4]);
    }
    if (meterAttrs[5]!=='') {
      meter.setAttribute('optimum',meterAttrs[5]);
    }
    
    if (par.cssId) {
      const cls = par.cssId.split(" ");
      cls.forEach((cl) => {
        if (cl.length > 0 && cl !== "NIL") {
          meter.classList.add(cl);
        }
      });
    }
    targetCell.appendChild(meter);
    updatePvpState(par.pvps[0], meter);
  } catch (err) {
    console.error(
      `Failed to render meter for pid=${par.id}, reason: ${err}`
    );
  }
}

/**
 *
 * @param {*} par
 * @param {*} targetCell
 */
function createMetaTag(par, targetCell) {
  try {
    const head = document.getElementsByTagName("head")[0]; //document.querySelector('head');
    if (par.pvps[0].dvalue.length > 0) {
      let value = par.pvps[0].dvalue;
      let metaTag = document.createRange().createContextualFragment(value);
      head.appendChild(metaTag);
    }
  } catch (err) {
    console.error(
      `Failed to creat meta/link tag for pid=${par.id}, reason: ${err}`
    );
  }
}
