/**
 * Adds the listeners after the fragment is appended to DOM.
 */
import { global as g } from "../globals.js";
import validateInput from "./validators.js";
import handleDelta from "./deltaChangeHandler.js";
import updateSelectMany from "./deltaChangeHandler.js";
import sendRequest, {
  SELECT_URL,
  DO_URL,
  PAGE_OPEN_URL,
  PAGE_CLOSE_URL,
  CHANNEL_URL,
  CLICK_URL,
  SEARCH_URL,
} from "./createRequest.fetch.js";
import openPage from "../page-builder";
import { showMessage, groupConditionalMandatoryMessages, parseExodusEvent } from "./utils";
import processSpecialActions from "./specialActionHandler.js";
import html2canvas from "html2canvas";
import { URLSearchParams } from "url";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";

var pause = false;
const querystring = require("querystring");

export default function addListeners(domBlocks) {
  // Nodes need to be on document before attaching listeners
  domBlocks.forEach((singleBlock) => {
    if (singleBlock.attachListeners) {
      singleBlock.attachListeners();
    }
  });

  const containers = Array.from(document.querySelectorAll(`.${g.exoContainer}`));
  containers.forEach((container) => {
    container.addEventListener("change", handleChange);
    // container.dataset.el = true;
  });

  const exolsnrs = Array.from(document.querySelectorAll("#pBOMID_1,#pBOMName_1,#pConfigName_1,#pPVSEQID_1"));
  exolsnrs.forEach((exolsnr) => {
    // we need to sequence config/bom selections
    exolsnr.addEventListener("change", handleExoChange);
    exolsnr.addEventListener("exo:change", handleExoChange);
  });

  const searchInputs = Array.from(document.querySelectorAll("input.exo-search"));
  searchInputs.forEach((search) => {
    // do the search
    search.addEventListener("change", handleSearchEvent);
    // record search string
    search.addEventListener("change", handleChange);
  });

  // event handler assumes there is only one toggle pid on the page atm
  const toggleInputs = Array.from(document.querySelectorAll(".exo-toggle"));
  toggleInputs.forEach((toggle) => {
    toggle.addEventListener("exoToggle", handleToggleEvent);
  });

  // onclick event for generating zip
  const zipButtons = Array.from(document.querySelectorAll(".exo-zip"));
  zipButtons.forEach((zipButton) => {
    zipButton.addEventListener("click", createZip, false);
    zipButton.buttonParam = zipButton.getAttribute("data-filename");
  });

  const customSelectors = Array.from(document.querySelectorAll(".custom-s"));
  customSelectors.forEach((customS) => {
    customS.addEventListener("click", handleCustomSChange);
    // customS.dataset.el = true;
  });

  const inputs = Array.from(document.querySelectorAll("input:not(:is(.select-many))"));
  inputs.forEach((input) => {
    input.addEventListener("keydown", handleKeyDown);
  });

  // select many trigger. needs to be jquery as
  // plain js does not intercept the ivent
  $('.dropdown').on('hidden.bs.dropdown', function(e) {
    // updateSelectMany(e.target);
    handleChangeMany(e);
  });

  const exoMethods = Array.from(document.querySelectorAll("[data-event^='method']"));
  exoMethods.forEach((method) => {
    method.addEventListener("click", handleMethodClickEvent);
  });

  const exoEvents = Array.from(document.querySelectorAll("[data-event^='eventType']"));
  exoEvents.forEach((exoEvent) => {
    exoEvent.addEventListener("click", handleExodusClickEvent);
  });

  const pageNameEvents = Array.from(document.querySelectorAll("[data-event^='pageName']"));
  pageNameEvents.forEach((exoEvent) => {
    exoEvent.addEventListener("click", handlePageNameClickEvent);
  });

  const mediaEvents = Array.from(document.querySelectorAll("video"));
  mediaEvents.forEach((exoEvent) => {
    exoEvent.addEventListener("play", recordNavClick);
    exoEvent.addEventListener("ended", recordNavClick);
    exoEvent.addEventListener("pause", recordNavClick);
    exoEvent.addEventListener("seeking", recordNavClick);
  });

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  $(".carousel").carousel({
    // interval: 2000,
    touch: true,
  });

  // range slider
  const sliders = Array.from(document.querySelectorAll('input[type="range"]'));
  sliders.forEach((slider) => {
    slider.addEventListener("input", updateSliderValue, false);
  });

  // record tab/pill clicks
  // const navs = Array.from(document.querySelectorAll('a.nav-link, .btn, .card, a[data-role="tooltip"]'));
  const navs = Array.from(document.querySelectorAll('a, .btn, .card'));
  navs.forEach((nav) => {
    nav.addEventListener("click", recordNavClick);
    // if (nav.dataset.role && nav.dataset.role === 'tooltip') {
    //   nav.addEventListener('focus', recordNavClick);
    // }
  });

  // add bom save listener by element id
  // document.getElementById('bom-save').addEventListener("click", saveBom);
  // add bom save listener by class name
  const bomSaveBtns = Array.from(document.querySelectorAll(".bom-save"));
  bomSaveBtns.forEach((btn) => {
    btn.addEventListener("click", saveBom);
  });

  // reset save bom modal
  // $('#saveBomModal').on('show.bs.modal', resetSaveBomModal);

  // at the moment, forms are used to upload files only
  const forms = Array.from(document.querySelectorAll("form.uvl"));
  forms.forEach((form) => {
    form.addEventListener("submit", uploadFile);
  });

  // searchable select
  $("select.searchable").selectpicker();

  // flip cards
  $(".flip-trigger").on("click", function () {
    // console.log('Flipped!');
    $(this).closest(".card").toggleClass("flipped");
    $(this).closest(".flip").children().toggleClass("card-abs");
  });

  // prevent default submition of saveBom form
  const saveBomModal = document.getElementById("saveBomModal");
  saveBomModal.querySelector("form").addEventListener("submit", function (e) {
    e.preventDefault();
  });

  // addListeners is called at the end of page build process
  // send DO request on page load
  $(document).ready(function () {
    sendDoRequest();
  });
}

function createZip(evt) {
  const links = Array.from(document.querySelectorAll(".exo-zippable"));
  fetch(g.dependencies["jsZip"])
    .then((response) => response.text())
    .then((text) => eval(text))
    .then(() => {
      fetch(g.dependencies["JSZipUtils"])
        .then((response) => response.text())
        .then((text) => eval(text))
        .then(() => {
          console.log("loaded");
          const zip = new JSZip();

          const zipFilename = "test.zip"; /*evt.currentTarget.buttonParam;*/
          let count = 0;
          console.log(links);
          links.forEach(async function (url) {
            const urlArr = (document.location.origin + url.getAttribute("href")).split("/");
            const filename = urlArr[urlArr.length - 1];
            try {
              const file = await JSZipUtils.getBinaryContent(url);
              zip.file(filename, file, { binary: true });
              count++;
              if (count === links.length) {
                zip.generateAsync({ type: "blob" }).then(function (content) {
                  fetch(g.dependencies["fileSaver"])
                    .then((response) => response.text())
                    .then((text) => eval(text))
                    .then(() => {
                      saveAs(content, zipFilename);
                    });
                });
              }
            } catch (err) {
              console.log(err);
            }
          });
        });
    });
}

const delay = async (ms = 1000) => new Promise(resolve => setTimeout(resolve, ms))
async function handleExoChange(evt) {
  while(pause) {
    await delay(100);
  }
  $("#waitModal").modal("show");
  handleChange(evt);
}

/**
 *
 * @param evt
 * @returns  && !target.classList.contains('bom-select')
 */
// async
export function handleChange(evt) {
  // console.log('Event: ', evt);
  try {
    evt.stopPropagation();
    evt.preventDefault();
  } catch (e) {
    // ignore
    // this is required because we are calling this function from
    // handleCustomSChange and passing just "target" - not the event
  }

  // if prev Ack Required response has not arrived yet
  if (pause) {
    $("#waitModal").modal({ backdrop: false, focus: false });
    rollBackChange(evt.target);
    return;
  }

  let { target } = evt;
  let { parentElement } = target;
  let selected = isSelected(target);

  // check if this is select many and
  // stop processing if neccessary
  if (target.classList.contains("select-many")) {
    target.closest("fieldset").classList.add("changed");
    return;
  }

  switch (target.type) {
    case "file":
      return;

    case "textarea":
    case "text":
      const { valid, msg } = validateInput(target);
      // console.log(`valid=${valid}, msg=${msg}`);
      if (!valid) {
        showValidationError(target, msg, true);
        evt.preventDefault();
        return;
      } else {
        showValidationError(target, msg, false);
      }
      break;

    default:
      target = resolveTargetAndSaveSelectedPvpForFutureDeSelect(evt);
  }

  const { value, dataset, id } = target;
  const { pid, ack } = dataset;

  if (ack === "1") {
    pause = true;
  }

  const exoDataset = document.querySelector(`.${g.exoContainer}`).dataset;
  let parentCellId = "";
  // used to loop only so many levels up in search of cellId
  let parentLevel = 0;
  // look for parent cell
  while (
    !parentElement.id.includes("c-") &&
    parentLevel < 6 &&
    !parentElement.classList.contains(g.exoContainer)
  ) {
    parentElement = parentElement.parentElement;
    parentLevel++;
  }

  if (parentElement.id.includes("c-")) {
    parentCellId = parentElement.id;
  }

  const payload = {
    eventType: 5,
    myPageViewSeqId: exoDataset.pvsId,
    targetPageId: exoDataset.pageId,
    myPageId: exoDataset.myPageId,
    defaultFlag: 1,
    callback: "",
    targetAliasName: "",
    longLinkList: [
      {
        paramId: pid,
        paramValueId: id,
        ackReq: ack,
        selected: selected ? 1 : 0,
        strength: 5,
        computeValue: value,
      },
    ],
    combinedSave: {},
    combinedUpdate: {},
  };

  (async () => {
    const { status, statusText, data } = await sendRequest(SELECT_URL, payload);
    if (status >= 200 && status < 400) {
      // for no-ack pids set state to 4
      if (target.dataset.ack === "0") {
        target.dataset.state = "4";
      }
      // apply delta changes
      handleDelta(data);
      // process special actions
      processSpecialActions(data.sa);
      // fire the DO event
      const doResult = await sendDoRequest();
      // done
      pause = false;
    } else {
      if (data.message) {
        showMessage(data.message);
      }
    }

    $("#waitModal").modal("hide");
  })();
}

function handleChangeMany(evt) {
  // console.log("Handling change many");
  try {
    evt.stopPropagation();
    evt.preventDefault();
  } catch (e) {}

  // if prev Ack Required response has not arrived yet
  if (pause) {
    $("#waitModal").modal({ backdrop: false, focus: false });
    rollBackChange(evt.target);
    return;
  }

  const { target } = evt;
  const exoDataset = document.querySelector(`.${g.exoContainer}`).dataset;
  let fieldset = target.querySelector('fieldset');
  let isChanged = fieldset.classList.contains('changed');
  let inputs = Array.from(fieldset.querySelectorAll('input'));
  // let pause = false, changed = false;
  let changed = false;

  if (!isChanged) {
    return;
  }

  const payload = {
    eventType: 5,
    myPageViewSeqId: exoDataset.pvsId,
    targetPageId: exoDataset.pageId,
    myPageId: exoDataset.myPageId,
    defaultFlag: 1,
    callback: "",
    targetAliasName: "",
    longLinkList: [
      
    ],
    combinedSave: {},
    combinedUpdate: {},
  };

  inputs.forEach((input) => {
    let selected = isSelected(input);
    const { value, dataset, id, disabled } = input;
    const { pid, ack, state } = dataset;
    
    if (selected && !disabled && state !== '5') {
      // console.log(`Chanded control: ${input.id}`);

      if (ack === "1") {
        pause = true;
      }

      const pvp = {
        paramId: pid,
        paramValueId: id,
        ackReq: ack,
        selected: selected ? 1 : 0,
        strength: 5,
        computeValue: value,
      };

      payload.longLinkList.push(pvp);
    }
  });

  // all inputs are deselected and at least one pvp is changed
  if (payload.longLinkList.length === 0 && isChanged) {
    // when all controlls are deselected
    // it is the same case as select all
    inputs.forEach((input) => {
      
      const { value, dataset, id } = input;
      const { pid, ack, state } = dataset;

      if (ack === "1") {
        pause = true;
      }

      if (state === '4') {
        const pvp = {
          paramId: pid,
          paramValueId: id,
          ackReq: ack,
          selected: 0,
          strength: 5,
          computeValue: value,
        };

        payload.longLinkList.push(pvp);
      }
    });
  }

  if(payload.longLinkList.length > 0) {
    (async () => {
      const { status, statusText, data } = await sendRequest(SELECT_URL, payload);
      if (status >= 200 && status < 400) {
        // for no-ack pids set state to 4
        if (target.dataset.ack === "0") {
          target.dataset.state = "4";
        }
        // apply delta changes
        handleDelta(data);
        // process special actions
        processSpecialActions(data.sa);
        // fire the DO event
        const doResult = await sendDoRequest();
        // done
        pause = false;
      } else {
        if (data.message) {
          showMessage(data.message);
        }
      }

      $("#waitModal").modal("hide");

      fieldset.classList.remove('changed');
    })();
  }
} 

/**
 *
 */
function handleCustomSChange(evt) {
  try {
    evt.stopPropagation();
    evt.preventDefault();
  } catch (e) {}
  const { target } = evt;
  const sTarget = target.closest("[data-pid");
  handleChange({ target: sTarget });
}

/**
 *
 */
function rollBackChange(el) {
  if (el.type === "checkbox") {
    console.log(`Rolling back checkbox for ${el}`);
    if (el.checked) {
      el.checked = false;
    } else {
      el.checked = true;
    }
  } else if (el.type === "select-one") {
    console.log(`Last selected index: ${el.dataset.lastsel}`);
    el.selectedIndex = el.dataset.lastsel;
  }

  el.focus();
}

/**
 *
 */
function handleKeyDown(evt) {
  let enterOrTab = false;
  if (evt instanceof KeyboardEvent) {
    // console.log(`${evt.key} key pressed. pause variable: ${pause}`);
    enterOrTab = evt.key === "Enter" || evt.key === "Tab" ? true : false;
  } else {
    console.log(`${evt} mouse clicked. pause variable: ${pause}`);
  }

  if (pause && !enterOrTab) {
    $("#waitModal").modal({ backdrop: false, focus: false });
    evt.preventDefault();
  }
}

/**
 *
 */
export function handleMethodClickEvent(evt) {
  // console.log(evt);
  evt.preventDefault();
  let allGood = true;
  document.body.mandatoryPids.forEach(function (pid) {
    try {
      let pidNode = document.getElementById(pid);
      // console.log("PID NODE (1)");
      // console.log(pidNode);
      if (!pidNode) {
        // for inputtext and textarea
        pidNode = document.querySelector(`[data-pid='${pid}']`);
        // console.log("PID NODE (2)");
        // console.log(pidNode);
      }
      if (!isPidSelected(pidNode)) {
        allGood = false;
        pidNode.classList.add("border", "border-danger", "rounded");
        try {
          let bb = pidNode.closest('.tab-pane');
          let bid = bb.id.split('-')[1];
          document.querySelector(`li#blockh-${bid} a`).classList.add('exo-mandatory');
        } catch (ee) {
          console.log(ee);
        }
        
      } else {
        try {
          let bb = pidNode.closest('.tab-pane');
          let bid = bb.id.split('-')[1];
          document.querySelector(`li#blockh-${bid} a`).classList.remove('exo-mandatory');
        } catch(ee) {
          //console.log(ee);
        }
        pidNode.classList.remove("border", "border-danger", "rounded");
      }
    } catch (e) {
      console.log(e);
    }
  });

  const exoEvent = querystring.parse(evt.target.dataset.event);
  if (allGood) {
    switch (`${exoEvent.method}`.toLowerCase()) {
      case "pageclose":
        // console.log("method pageClose");
        closePage(evt);
        break;
      case "closemethod1":
        // console.log("method closeMethod1");
        break;
      case "pagereset":
        // console.log("method pageReset");
        break;
      case "save":
        // this is obsolete in the rewrite as we want to allow
        // user to save page even if mandatories are not satisfied
        // console.log("method save");
        // showSaveBomModal();
        break;
      case "bomview":
      case "viewbom":
        // console.log("method viewbom");
        transitionToBom(evt);
        break;
      case "bomopen":
        openBom(evt);
        break;
      case "bomdelete":
        deleteBom(evt);
        break;
      case "external":
        // console.log("method viewprint");
        callExternalService(exoEvent);
        break;
      case "scrprint":
        // console.log("method scrPrint");
        printToPdf(evt);
        break;
      case "configdelete":
        console.log("About to delete bom config");
        deleteBomConfig(evt);
        break;
      case "configedit":
        console.log("About to edit bom config");
        editBomConfig(evt);
        break;
      case "configcopy":
        console.log("About to copy bom config");
        break;
      default:
        // console.log("method default");
        break;
    }
  }
}

/**
 *
 */
async function transitionToBom(evt) {
  await _transitionToBom(evt);
}

/**
 *
 */
async function _transitionToBom(evt) {
  // const exoEvent = querystring.parse(evt.target.dataset.event);
  const bomName = document.getElementById("pBOMName_1");
  const bomId = document.getElementById("pBOMID_1");
  console.log(`bomId: ${bomId.value}`);
  const exo = document.querySelector(`.${g.exoContainer}`);
  const { eventType, targetPageId, defaultFlag } = querystring.parse(evt.target.dataset.event);

  let payload = {
    eventType: 14,
    myPageViewSeqId: exo.dataset.pvsId,
    myPageId: exo.dataset.myPageId,
    targetPageId: exo.dataset.myPageId,
    defaultFlag: "1",
    callbackURL: "",
    targetAliasName: "",
    longLinkList: [
      {
        paramId: "pBOMName",
        paramValueId: "",
        ackReq: 0,
        selected: 1,
        strength: 5,
        computeValue: bomName.value,
      },
    ],
    combinedSave: {},
    combinedUpdate: {},
  };

  const { status, statusText, data } = await sendRequest(PAGE_CLOSE_URL, payload);
  const { messageList, page, pageId, pageViewSeqId } = data;
  if (messageList && messageList.length > 0) {
    // conditional mandatories are not satisfied
    showMessage(await groupConditionalMandatoryMessages(messageList));
  } else if (page) {
    // const { targetPageViewSeqId } = page;
    if (page.targetPageViewSeqId > 0) {
      // reopen bob page
      payload.eventType = 3;
      payload.myPageId = page.targetPageId;
      payload.targetPageId = pageId;
      payload.myPageViewSeqId = page.targetPageViewSeqId;
      $("#waitModal").modal("show");
      openPage(payload);
      // } else if (callBackUrl.length > 0) {
      //   // open bom page
      //   window.location.replace(callBackUrl);
    } else {
      // open bom page
      payload.eventType = 11;
      payload.myPageId = exo.dataset.myPageId;
      payload.targetPageId = targetPageId;
      payload.myPageViewSeqId = exo.dataset.pvsId;
      payload.longLinkList = [];
      payload.bomId = bomId != null && bomId.value.length>0?bomId.value:bomName != null && bomName.value.length?bomName.value:'n/a';
      $("#waitModal").modal("show");
      openPage(payload);
    }
  }
}

/*
 *
 */
function openBom(evt) {
  const bomName = document.getElementById("pBOMName_1");
  const bomId = document.getElementById("pBOMID_1");
  console.log(`bomId: ${bomId.value}, bomName: ${bomName.value}`);
  const exo = document.querySelector(`.${g.exoContainer}`);
  // const { eventType, targetPageId, defaultFlag } = querystring.parse(evt.target.dataset.event);

  let payload = {
    eventType: 15,
    myPageViewSeqId: exo.dataset.pvsId,
    myPageId: exo.dataset.myPageId,
    targetPageId: exo.dataset.myPageId,
    defaultFlag: "1",
    callbackURL: "",
    targetAliasName: "",
    longLinkList: [
      {
        paramId: "pBOMName",
        paramValueId: "",
        ackReq: 0,
        selected: 1,
        strength: 5,
        computeValue: bomName.value,
      },
    ],
    combinedSave: {},
    combinedUpdate: {},
  };

  (async () => {
    const { status, statusText, data } = await sendRequest(PAGE_OPEN_URL, payload);
    if (status >= 200 && status < 400) {
      const {page} = data;
      console.log(page);
      payload.eventType = 3;
      payload.myPageId = page.targetPageId;
      payload.targetPageId = "0";
      payload.myPageViewSeqId = page.targetPageViewSeqId;
      payload.longLinkList = [];
      $("#waitModal").modal("show");
      openPage(payload);
    } 
  })();
}

/*
 *
 */
function deleteBom(evt) {
  const bomName = document.getElementById("pBOMName_1");
  console.log(`Delete BOM - bomName: ${bomName.value}`);
  const exo = document.querySelector(`.${g.exoContainer}`);
  // const { eventType, targetPageId, defaultFlag } = querystring.parse(evt.target.dataset.event);
  if(bomName.value === "") {
    showMessage("Please select BOM to delete.");
  } else {
    let payload = {
      eventType: 18,
      myPageViewSeqId: exo.dataset.pvsId,
      myPageId: exo.dataset.myPageId,
      targetPageId: exo.dataset.myPageId,
      defaultFlag: "1",
      callbackURL: "",
      targetAliasName: "",
      longLinkList: [
        {
          paramId: "pBOMName",
          paramValueId: "",
          ackReq: 0,
          selected: 1,
          strength: 5,
          computeValue: bomName.value,
        },
      ],
      combinedSave: {},
      combinedUpdate: {},
    };

    (async () => {
      const { status, statusText, data } = await sendRequest(SELECT_URL, payload);
      if (status >= 200 && status < 400) {
        const doResult = await sendDoRequest();
        configName.value = "";
        configId.value = "";
      } 
    })();
  }
}

/*
 *
 */
function deleteBomConfig(evt) {
  const bomName = document.getElementById("pBOMName_1");
  const configId = document.getElementById("pConfigID_1");
  const configName = document.getElementById("pConfigName_1");
  // console.log(`bom name: ${bomName.value}, config id: ${configId}`);
  if(bomName.value === "" || configId.value === "" || configName.value === "") {
    showMessage("Please select configuration to delete.");
  } else {
    const exo = document.querySelector(`.${g.exoContainer}`);
    let payload = {
      eventType: 17,
      myPageViewSeqId: exo.dataset.pvsId,
      myPageId: exo.dataset.myPageId,
      targetPageId: exo.dataset.myPageId,
      defaultFlag: "1",
      callbackURL: "",
      targetAliasName: "",
      longLinkList: [
        {
          paramId: "pBOMName",
          paramValueId: "",
          ackReq: 0,
          selected: 1,
          strength: 5,
          computeValue: bomName.value,
        },
        {
          paramId: "pConfigID",
          paramValueId: "",
          ackReq: 0,
          selected: 1,
          strength: 5,
          computeValue: configId.value,
        },
      ],
      combinedSave: {},
      combinedUpdate: {},
    };

    (async () => {
      const { status, statusText, data } = await sendRequest(SELECT_URL, payload);
      if (status >= 200 && status < 400) {
        const doResult = await sendDoRequest();
        configName.value = "";
        configId.value = "";
      } 
    })();
  }
}

/*
 *
 */
async function editBomConfig(evt) {
  const configId = document.getElementById("pConfigID_1");
  if(configId.value === "") {
    showMessage("Please select configuration to edit.");
  } else {
    let payload = {
      eventType:"3",
      myPageId:"0",
      targetPageId:"0",
      defaultFlag:"1",
      callbackURL:"",
      targetAliasName:"",
      myPageViewSeqId:configId.value,
      copyType:"0",
      oppId:"0",
      debugFlag:"0",
      combinedSave:{},
      combinedUpdate:{},
      longLinkList:[]
    };

    $("#waitModal").modal("show");
    openPage(payload);
  }
}

/**
 *
 */
async function closePage(evt) {
  await _closePage(evt);
}

/**
 *
 */
async function _closePage(evt, redirect = true) {
  const exo = document.querySelector(`.${g.exoContainer}`);
  let payload = {
    eventType: 4,
    myPageViewSeqId: exo.dataset.pvsId,
    myPageId: exo.dataset.myPageId,
    targetPageId: exo.dataset.myPageId,
    defaultFlag: "1",
    callbackURL: "",
    targetAliasName: "",
    longLinkList: [],
    combinedSave: {},
    combinedUpdate: {},
  };
  // we need this to call from method=external handler
  let closed = true;

  const { status, statusText, data } = await sendRequest(PAGE_CLOSE_URL, payload);
  const { messageList, page, pageId, pageViewSeqId } = data;
  if (messageList && messageList.length > 0) {
    // conditional mandatories are not satisfied
    showMessage(await groupConditionalMandatoryMessages(messageList));
    closed = false;
  } else if (page) {
    const { targetPageViewSeqId, targetPageId, callBackUrl } = page;
    // console.log(page);
    if (targetPageViewSeqId > 0) {
      // reopen parent page
      payload.eventType = 3;
      payload.myPageId = targetPageId;
      payload.targetPageId = pageId;
      payload.myPageViewSeqId = targetPageViewSeqId;
      $("#waitModal").modal("show");
      openPage(payload);
    } else if (redirect) {
      if (callBackUrl.length > 0) {
        // redirect to the callback url (or just show the blank page ?)
        window.location.replace(callBackUrl);
      } else {
        window.location.replace("/");
      }
    }
  }

  return closed;
}

/**
 *
 */
export async function closeChildPage(evt) {
  evt.preventDefault();
  const eventNode = evt.target.closest("[data-event]");
  const exoEvent = querystring.parse(eventNode.dataset.event);
  const payload = {
    eventType: 12,
    myPageViewSeqId: exoEvent.pageViewSeqId,
    myPageId: exoEvent.pageId,
    targetPageId: exoEvent.pageId,
    defaultFlag: exoEvent.defaultFlag,
    callbackURL: "",
    targetAliasName: "",
    longLinkList: [],
    combinedSave: {},
    combinedUpdate: {},
  };

  if (confirm("Page delete can not be undone. Proceed?")) {
    $("#waitModal").modal("show");
    const { status, statusText, data } = await sendRequest(PAGE_CLOSE_URL, payload);
    const { page, pageId, pageViewSeqId } = data;
    if (page) {
      const { targetPageViewSeqId, targetPageId, callBackUrl } = page;
      console.log(page);
      if (targetPageViewSeqId > 0) {
        // reopen parent page
        payload.eventType = 3;
        payload.myPageId = targetPageId;
        payload.targetPageId = pageId;
        payload.myPageViewSeqId = targetPageViewSeqId;
        openPage(payload);
      }
    }
  }
}

/**
 *
 */
export async function copyToClipboard(evt) {
  evt.preventDefault();
  const eventNode = evt.target.closest("[data-copy]");
  let str = eventNode.dataset.copy;
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  alert("Copied to clipboard");
}

// /**
//  *
//  */
// function showSaveBomModal() {
//   const exo = document.querySelector(`.${g.exoContainer}`);
//   if (!exo.dataset.bomId) {
//     $('#saveBomModal').modal('show');
//   } else {
//     showMessage("Previously saved BOM exists for this page!");
//   }
// }

/**
 *
 */
async function saveBom(evt) {
  const bomName = document.querySelector("[data-pid='pBOMName']");
  const configName = document.querySelector("[data-pid='pConfigName']");
  const bomId = document.querySelector("[data-pid='pBOMID']");
  const olnode = configName.closest("section.modal");
  const exo = document.querySelector(`.${g.exoContainer}`);

  const payload = {
    eventType: 8,
    myPageViewSeqId: exo.dataset.pvsId,
    myPageId: exo.dataset.myPageId,
    targetPageId: exo.dataset.myPageId,
    defaultFlag: "1",
    callbackURL: "",
    targetAliasName: "",
    longLinkList: [],
    combinedSave: {
      saveFlag: 1,
      password: "password",
      oppId: bomId.value.trim().length > 0 ? bomId.value : "",
      activityId: 0,
      requestId: 0,
      oppName: bomName.value,
      activityName: configName.value,
      requestName: "",
    },
    combinedUpdate: {},
  };

  // console.log(`ConfigName: ${configName.value}, BOMName: ${bomName.value}`);
  let goodToGo = true;
  {
    const { valid, msg } = validateInput(bomName);
    if (bomName.value.length === 0) {
      showValidationMessageInModal(bomName, "Please enter a valid name");
      goodToGo = false;
    } else if (!valid) {
      showValidationMessageInModal(bomName, msg);
      goodToGo = false;
    }
  }

  {
    const { valid, msg } = validateInput(configName);
    // console.log(`Config name validation: valid2=${valid}, msg2=${msg}`);
    if (configName.value.length === 0) {
      showValidationMessageInModal(configName, "Please enter a valid name");
      goodToGo = false;
    } else if (!valid) {
      showValidationMessageInModal(configName, msg);
      goodToGo = false;
    }
  }

  if (goodToGo) {
    const { status, statusText, data } = await sendRequest(PAGE_OPEN_URL, payload);
    if (status >= 200 && status <= 400) {
      handleDelta(data);
      $(`#${olnode.id}`).modal("hide");
      // if multiple versions of BS4 are loaded (3rd party header/footer)
      // ol gets multiple backdrops. trigger(click) magically takes care of it
      $(`#${olnode.id}`).trigger("click");
    } else {
      let msg;
      if (status === 409) {
        msg = "The specified name already exists. Please use a different one!";
      }
      showValidationMessageInModal(bomName, msg);
    }
  }
}

function uploadFile(evt) {
  // set modal loading sign ON
  $("#waitModal").modal("show");
  evt.preventDefault();
  const { currentTarget } = evt;
  fetch('/uvl', setOptions(currentTarget))
    .then((response) => onSuccess(response, currentTarget))
    .catch((error) => onError(error));
}

function setOptions(currentForm) {
  const data = new FormData(currentForm);
  const exoContainer = document.querySelector(`.${g.exoContainer}`);
  data.append('pvsid', exoContainer.dataset.pvsId);
  data.append('pageid', exoContainer.dataset.pageId);
  const headers = new Headers({
    'X-CSRF-TOKEN': document.head.querySelector("[name='_csrf'][content]").content
  });
  return {
    method: 'post',
    credentials: "same-origin",
    headers,
    body: data,
  };
}

function onSuccess(response, currentTarget) {
  $("#waitModal").modal("hide");
  if (response.ok) {
    return response.json().then(function(data){
      const returnPid = currentTarget.querySelector('[data-return-pid="1"]');
      returnPid.value = data.pvsid;
      const changeEvent = new Event("change", { bubbles: true });
      // currentTarget.classList.add('d-none');
      returnPid.dispatchEvent(changeEvent);
    });
  } else {
    return response.json().then(function(data){
      showMessage(data.msg);
    });
  }
}

function onError(error) {
  $("#waitModal").modal("hide");
  console.error(error);
  showMessage("A network error occured");
}


/**
 *
 */
function showValidationMessageInModal(el, msg) {
  const parent = el.parentElement;
  const s = document.createRange().createContextualFragment(`<span class="text-danger">${msg}</span>`);
  while (parent.querySelector("span")) {
    parent.removeChild(parent.querySelector("span"));
  }
  parent.appendChild(s);
}

/**
 *
 * @param evt
 * @returns
 */
// function resetSaveBomModal(evt) {
//   const {target} = evt;
//   try {
//     const body = target.querySelector(".modal-body");
//     const parent = body.querySelector(".form-group");
//     parent.querySelector("input").value = "";
//     while (parent.querySelector('span')) {
//       parent.removeChild(parent.querySelector('span'));
//     }
//   } catch(e) {
//     console.warn(e);
//   }
// }

/**
 *
 * @param exoEvent - map of parameters defined in data-event="..."
 * @returns
 */
async function callExternalService(exoEvent) {

  $("#waitModal").modal("show");

  // 1. send page close event=4
  // 2. send request to the service channel
  // 3. send update request event=9

  // 1. validate if there are no conditional mandatory so we can proceed
  const closed = await _closePage(exoEvent, false);
  if (closed) {
    const exo = document.querySelector(`.${g.exoContainer}`);
    const payload = {
      eventType: exoEvent.service,
      myPageViewSeqId: exo.dataset.pvsId,
      myPageId: exo.dataset.myPageId,
      targetPageId: exo.dataset.myPageId,
      defaultFlag: 1,
      callbackURL: "",
      targetAliasName: "",
      debugFlag: "0",
      longLinkList: [],
      combinedSave: {},
      combinedUpdate: {},
      integrationPageViewSeqId: "",
    };

    // 2. call target service
    const { status, statusText, data } = await sendRequest(CHANNEL_URL, payload);
    if (status >= 200 && status <= 400) {
      const { message, uri } = data;
      let msg;
      if (message && uri) {
        msg = `<a href="${uri}" target="_blank">${message}</a>`;
      } else if (message) {
        msg = message;
      }
      $("#waitModal").modal("hide");
      await showMessage(msg);
    }

    // 3. send update request
    payload.eventType = 9;
    payload.combinedUpdate = { myPageAlias: "test alias", freezeFlag: 0, requestStatus: "submitted" };
    await sendRequest(PAGE_OPEN_URL, payload);
  }
}

/**
 *
 * @returns
 */
function printToPdf(event) {
  const filename = "export.pdf";
  let { target } = event;
  try {
    target.parentNode.removeChild(target);
  } catch (e) {
    console.info("Failed to remove Print button: ", e);
  }

  // html2canvas(document.querySelector('body')).then(canvas => {
  html2canvas(document.querySelector(`.${g.exoContainer}`)).then((canvas) => {
    let pdf = new jsPDF("p", "pt", "letter");
    let width = pdf.internal.pageSize.getWidth();
    let height = pdf.internal.pageSize.getHeight();

    let widthRatio = width / canvas.width;
    let heightRatio = height / canvas.height;

    let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    pdf.addImage(
      canvas.toDataURL("image/jpeg", 1.0),
      "JPEG",
      10,
      10,
      canvas.width * ratio - 20,
      canvas.height * ratio - 20
    );
    pdf.save(filename);
  });
}

/**
 *
 */
export function handleExodusClickEvent(evt) {
  // console.log(evt);
  try {
    evt.preventDefault();
    evt.stopPropagation();
  } catch (e) {
    console.error("Failed to stop event propagation: ", e);
  }
  const exo = document.querySelector(`.${g.exoContainer}`);
  const exoEvent = querystring.parse(evt.target.dataset.event);
  const llParameters = parseExodusEvent(exoEvent);

  const payload = {
    eventType: exoEvent.eventType,
    myPageViewSeqId: exo.dataset.pvsId,
    myPageId: exo.dataset.myPageId,
    targetPageId: exoEvent.targetPageId,
    defaultFlag: exoEvent.defaultFlag,
    callbackURL: "",
    targetAliasName: "",
    longLinkList: llParameters,
    combinedSave: {},
    combinedUpdate: {},
  };
  switch (exoEvent.eventType) {
    case "3":
      // page re-open
      // console.log("Page re-open");
      payload.myPageViewSeqId = exoEvent.pageViewSeqId;
      payload.myPageId = exoEvent.pageId;
    case "11":
    case "1":
    default:
      // console.log("Default case");
      try {
        const backdrops = Array.from(document.querySelectorAll("div.modal-backdrop"));
        backdrops.forEach((backdrop) => {
          backdrop.parentElement.removeChild(backdrop);
        });
      } catch (e) {
        log.info(`error removing backdrop: ${e}`);
      }
      $("#waitModal").modal("show");
      openPage(payload);
  }
}

/**
 *
 */
export function handlePageNameClickEvent(evt) {
  // console.log(evt);
  try {
    evt.preventDefault();
    evt.stopPropagation();
  } catch (e) {
    console.error("Failed to stop event propagation: ", e);
  }
  const exo = document.querySelector(`.${g.exoContainer}`);
  const exoEvent = querystring.parse(evt.target.dataset.event);
  // console.log(exoEvent);
  const llParameters = parseExodusEvent(exoEvent);
  // console.log(llParameters);

  const payload = {
    eventType: exoEvent.eventType ? exoEvent.eventType : "",
    myPageViewSeqId: exo.dataset.pvsId,
    myPageId: exo.dataset.myPageId,
    targetPageId: "",
    defaultFlag: exoEvent.defaultFlag ? exoEvent.defaultFlag : "",
    callbackURL: "",
    targetAliasName: "",
    longLinkList: llParameters,
    combinedSave: {},
    combinedUpdate: {},
    targetPageName: exoEvent.pageName,
  };

  switch (exoEvent.eventType) {
    case "3":
      // page re-open
      // console.log("Page re-open");
      payload.myPageViewSeqId = exoEvent.pageViewSeqId;
      payload.myPageId = exoEvent.pageId;
    case "11":
    case "1":
    default:
      // console.log("Default case");
      try {
        const backdrops = Array.from(document.querySelectorAll("div.modal-backdrop"));
        backdrops.forEach((backdrop) => {
          backdrop.parentElement.removeChild(backdrop);
        });
      } catch (e) {
        log.info(`error removing backdrop: ${e}`);
      }
      $("#waitModal").modal("show");
      openPage(payload);
  }
}

/**
 * Resolve pvp (option) target for SELECT control. Cache selected pvp in select
 * or fieldset node for future deselect.
 */
function resolveTargetAndSaveSelectedPvpForFutureDeSelect(evt) {
  let { target } = evt;
  if (target.tagName === "SELECT" || target.type === "select-one") {
    if (target.selectedIndex > 0) {
      // save selected option idx to data-selected attr for future de-select
      // handling
      target.dataset.lastsel = target.selectedIndex;
      target = target.childNodes[target.selectedIndex];
    } else {
      target = target.childNodes[target.dataset.lastsel];
      evt.target.dataset.lastsel = 0;
    }
  } else if (target.type === "checkbox") {
    // this block is for "select one" only. "select many" is not supported yet.
    // if lastsel is set then it needs to be unchecked as different checkbox is
    // selected
    let fs = target.closest("fieldset");
    if (fs.dataset.lastsel) {
      try {
        fs.querySelector("input#" + fs.dataset.lastsel).checked = false;
      } catch (e) {
        console.error(`Failed to uncheck previously selected checkbox. Reason: ${e}`);
      }
    } else {
      // this is right after page load - no selections are made yet
      const inputs = Array.from(fs.querySelectorAll("input"));
      inputs.forEach((input) => {
        if (input.id !== target.id) {
          input.checked = false;
        }
      });
    }
    fs.dataset.lastsel = target.id;
  } else if (target.classList.contains("custom-s")) {
    // this block is for CheckBoxCustom "select one" only. "select many" is not supported yet.
    let fs = target.closest(`#${target.dataset.pid}`);
    if (fs.dataset.lastsel) {
      try {
        fs.querySelector(`#${fs.dataset.lastsel}`).dataset.state = "0";
      } catch (e) {
        console.error(`Failed to uncheck previously selected checkboxcustom . Reason: ${e}`);
      }
    } else {
      // this is right after page load - no selections are made yet
      fs.childNodes.forEach((input) => {
        if (input.id !== target.id) {
          input.dataset.state = "0";
        }
      });
    }
    fs.dataset.lastsel = target.id;
  }
  return target;
}

/**
 *
 */
function updateSliderValue(evt) {
  let { target } = evt;
  const out = target.parentNode.querySelector("span");
  if (out) {
    out.innerHTML = target.value;
    out.dataset.level = parseFloat(target.value / target.max).toPrecision(1);
  } else {
    console.error(`Can not locate range-out to output value ${target.value}`);
  }
}

/**
 *
 */
export function recordNavClick(evt) {
  // console.log("About to record a click:");
  // console.log(evt);
  const { target } = evt;
  const exoContainer = document.querySelector(`.${g.exoContainer}`);

  let _type = target.dataset.role,
    _target,
    _info = "";
  // console.log(`type(1) = ${_type}`);
  if (!_type) {
    _type = target.getAttribute("role");
    if (!_type) {
      _type = target.nodeName;
    }
  }

  switch (_type) {
    case "tooltip":
      _target = target.dataset.title ? target.dataset.title : "n/a";
      break;
    case "button":
      _target = target.dataset.pid ? target.dataset.pid : "n/a";
      if (target.dataset.event) {
        _info = target.dataset.event;
      } else if (target.dataset.method) {
        _info = target.dataset.method;
      } else {
        _info = "n/a";
      }
      break;
    case 'img':
    case 'IMG':
      _target = 'IMG';
      _info = target.getAttribute("src");
      break;
    case "A":
      if (target.hasAttribute("href")) {
        _info = target.getAttribute("href");
      } else if (target.dataset.event) {
        _info = target.dataset.event;
      } else if (target.dataset.method) {
        _info = target.dataset.method;
      } else {
        _info = "n/a";
      }
    case "card":
    case "VIDEO":
      _type = `${_type}: ${evt.type}`;
    default:
      _target = evt.target.innerHTML;
  }
  // console.log("_type:"+JSON.stringify(_type));
  // console.log("_info:"+JSON.stringify(_info));
  // console.log("_target:"+JSON.stringify(_target));

  _type = _type.replace(/"/g,"");
  _info = _info.replace(/"/g,"");
  _target = _target.replace(/"/g,"");
  _target = _target.replace(/[\n\r]/g,"");
  const payload = {
    target: `${_target}|${_info}`,
    pvsid: exoContainer.dataset.pvsId,
    type: _type,
    info: _info,
  };
  sendRequest(CLICK_URL, payload);
}

/**
 *
 */
async function handleSearchEvent(evt) {
  try {
    // evt.stopPropagation();
    evt.preventDefault();
  } catch (e) {
    // ignore
    // this is required because we are calling this function from
    // handleCustomSChange and passing just "target" - not the event
  }
  // console.log(evt);
  const { target } = evt;
  const exoContainer = document.querySelector(`.${g.exoContainer}`);
  const searchString = target.value;
  const payload = {
    q: searchString.trim(),
    pid: target.dataset.pid,
    pvsid: exoContainer.dataset.pvsId,
    pageid: exoContainer.dataset.pageId,
  };

  const response = await sendRequest(SEARCH_URL, payload);
  console.log(`Server response: ${JSON.stringify(response)}`);
  const { status, statusText, data } = response;

  if (status == 200) {
    if (data["record-count"] === 0 && searchString.trim().length > 0) {
      // show 'no results' message
      const msg = `No results found for "${searchString}"`;
      showMessage(msg);
    }
    const toggleEvent = new Event("exoToggle");
    document.querySelector(".exo-toggle").dispatchEvent(toggleEvent);
  } else {
    console.info(`FTS response status: ${response.status}`);
  }
}

/**
 *
 */
function handleToggleEvent(evt) {
  // console.log("Handling exoToggle event");
  // console.log(evt);
  const { target } = evt;
  const pvp = target.querySelector(`[data-pid=${target.id}]`);
  // console.log(pvp);
  if (pvp.checked) {
    pvp.checked = false;
  } else {
    pvp.checked = true;
  }

  const changeEvent = new Event("change", { bubbles: true });
  pvp.dispatchEvent(changeEvent);
}

/**
 *
 */
function isPidSelected(target) {
  // console.log(target);
  let selected = false;
  switch (target.nodeName) {
    case "SELECT":
      // console.log("Event target = select-one");
      if (target.selectedIndex !== 0) {
        selected = true;
      }
      break;
    case "FIELDSET":
      // console.log("Event target = fieldset");
      const checkboxes = Array.from(target.querySelectorAll("[type='checkbox']"));
      checkboxes.forEach((cb) => {
        if (cb.checked) {
          selected = true;
        }
      });
      break;
    case "TEXTAREA":
    // console.log("Event target = textarea");
    case "INPUT":
      // at the moment this is just input.text
      // may need to differentiate by type in the future
      // console.log("Event target = text");
      selected = target.value.length > 0;
      break;
    default:
      console.log("Event target = other");
      // code to handle custom-s selectors
      const customSs = Array.from(target.querySelectorAll(".custom-s"));
      customSs.forEach((cs) => {
        if (cs.dataset.state === "4" || cs.dataset.state === "2") {
          selected = true;
        }
      });
  }

  return selected;
}

/**
 * TODO: add textarea
 */
function isSelected(target) {
  let selected = true;
  switch (target.type) {
    case "select-one":
      if (target.selectedIndex === 0) {
        selected = false;
      }
      break;
    case "checkbox":
      selected = target.checked;
      break;
    case "text":
      selected = target.value.length > 0;
      break;
    default:
      if (target.classList.contains("custom-s")) {
        selected = target.dataset.state === "4" ? false : true;
        // console.log(`Section id=${target.id}, selected=${selected}`);
      }
      break;
  }

  return selected;
}

/**
 *
 */
async function sendDoRequest() {
  const exoDataset = document.querySelector(`.${g.exoContainer}`).dataset;
  const payload = {
    eventType: 10,
    myPageViewSeqId: exoDataset.pvsId,
    targetPageId: exoDataset.pageId,
    myPageId: exoDataset.myPageId,
    defaultFlag: 1,
    callback: "",
    targetAliasName: "",
    longLinkList: [],
    combinedSave: {},
    combinedUpdate: {},
  };
  try {
    const { status, statusText, data } = await sendRequest(DO_URL, payload);
    return handleDelta(data);
  } catch (e) {
    return {"msg":null,"messageList":[],"pageMessageList":[],"tableData":{},"sa":[],"parameters":[]};
  }
}

/**
 *
 */
function showValidationError(target, msg, show) {
  let options = {
    content: msg,
    placement: "bottom",
  };
  if (show) {
    $("#" + target.id).popover(options);
    $("#" + target.id).popover("show");
  } else {
    $("#" + target.id).popover("hide");
  }
}
