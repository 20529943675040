import { global as g } from '../globals.js';
import buildBlocks from './build-blocks/buildBlocks.js';
import loadCss from './loadCss.js';
import addContent from './addContent.js';
import addListeners from './eventHandlers.js';
import processSpecialActions from './specialActionHandler.js';
import processCharts from './chartHandler.js';

// response = data
export default function buildPage(response, bFrag) {
  
  loadCss(response.page.cssFile);

  const domBlocks = buildBlocks(response, bFrag);

  // adds static content, pids/pvps, child pages etc.
  addContent(response, bFrag);

  const exoContainer = document.querySelector(`.${g.exoContainer}`);
  try {
    // fragment needs to go on document before appending listeners
    exoContainer.appendChild(bFrag);
  } catch (e) {
    console.error("Failed to init page: "+e);
  }
  
  // add login/logout navigation
  try {
    positionHeadNav();
  } catch(e) {
    console.info("Failed to init login/logout navigation");
  }

  addListeners(domBlocks);
  
  processSpecialActions(response.sa);
  
  processCharts();
  
  setTimeout(function(){
	  $('#waitModal').modal('hide');
	  // console.info('Hiding modal on page load (100)');
  },100);
  
}

function positionHeadNav() {
  const nav_ = document.getElementById('head-nav');
  const navLoc = document.querySelector('div[data-id="head-nav-loc"]');
  if(nav_ && navLoc) {
    let nav = nav_.cloneNode(true);
    nav.id = `${nav.id}-live`;
    navLoc.appendChild(nav);
    nav.classList.remove('d-none');
    
    let logout = nav.querySelector('a#logout');
    logout.id = 'logout-live';
    logout.addEventListener("click",function(e){
      console.info('LOGOUT IS CLICKED');
      e.preventDefault();
      document.getElementById("logout-form").submit();
    });
  }
}
