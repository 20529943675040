import { global as g } from "../globals.js";
import { format } from "./formatters.js";
import sendRequest, { LOOKUP_URL } from "./createRequest.fetch.js";
import {
  handleExodusClickEvent,
  handleMethodClickEvent,
  handlePageNameClickEvent,
  recordNavClick,
} from "./eventHandlers";
import { updateChart } from "./chartHandler";
import { showMessage } from "./utils";

/**
 *
 */
export default function handleDelta(delta) {
  const { parameters, pageMessageList, tableData, bom } = delta;
  let result = true;
  try {
    processParameters(parameters);
    processServerMessages(pageMessageList);
    if (tableData !== undefined) {
      processTableData(tableData);
      processChart(tableData);
    }
    processBom(bom);
  } catch (e) {
    console.error(`Failed to process delta change ${e}`);
    result = false;
  }
  return result;
}

/**
 *
 */
function processParameters(parameters) {
  if (parameters && parameters.length > 0) {
    parameters.forEach((param) => {
      // console.log(param);
      const { pvps, hide, id } = param;
      let pidNode = document.getElementById(param.id);
      // show previously hidden parameter
      if (param.hasOwnProperty("hide") && hide === 0) {
        // let pidNode = document.getElementById(param.id);
        if (pidNode == null) {
          // input texts do not have parent element with id=param.id
          // and they have only one pvpid = <param.id>_1
          pidNode = document.getElementById(`${param.id}_1`);
        }
        console.log(`About to unhide node ${pidNode}`);
        try {
          pidNode
            .closest(".d-none, .invisible")
            .classList.remove("d-none", "invisible");
        } catch (e) {
          console.log(`Failed to unhide pid: ${param.id}`);
        }
      }

      if (pvps && pvps.length > 0) {
        pvps.forEach((pvp) => {
          // console.log(`Getting pvp node by id=${pvp.id}`);
          let pvpNode = document.getElementById(pvp.id);
          updatePvpState(pvp, pvpNode);
          // update Select Many
          if (pvpNode.classList.contains('select-many')) {
            updateSelectMany0(pvpNode);
          }
        });
      }

      if (pidNode && pidNode.nodeName === "SELECT") {
        if (pidNode.querySelector('[data-state="2"]')) {
          pidNode.disabled = true;
        } else {
          pidNode.disabled = false;
        }
      } else if (pidNode && pidNode.nodeName === "UL") {
        let show =
          pidNode.querySelectorAll('[data-state="4"]').length +
          pidNode.querySelectorAll('[data-state="2"]').length;
        // console.log(`Show flag = ${show}`);
        if (show !== 1) {
          pidNode.classList.add("d-none");
        } else {
          pidNode.classList.remove("d-none");
        }
      }
    });
  }
}

export function updatePvpState(pvp, pvpNode) {
  // console.log(pvpNode);
  switch (pvp.state) {
    case 0:
      // not disabled, not selected, not autoselected
      enablePvp(pvpNode, pvp);
      if (pvpNode.tagName === "OPTION") {
        // pvpNode.parentNode.disabled = false;
        pvpNode.selected = false;
      } else if (pvpNode.tagName === "LI") {
        pvpNode.classList.add("d-none");
      } else {
        switch (pvpNode.type) {
          case "checkbox":
            pvpNode.checked = false;
            break;
          default:
            break;
        }
      }
      break;

    case 1:
      // disable
      disablePvp(pvpNode, pvp);
      if (pvpNode.tagName === "OPTION") {
        // pvpNode.parentNode.disabled = false;
        pvpNode.selected = false;
      } else if (pvpNode.tagName === "LI") {
        pvpNode.classList.add("d-none");
      } else {
        switch (pvpNode.type) {
          case "checkbox":
            pvpNode.checked = false;
            break;
          default:
            break;
        }
      }
      break;

    case 2:
      // autoselect
      if (pvpNode.tagName === "OPTION") {
        // pvpNode.parentNode.disabled = true;
      } else if (pvpNode.tagName === "LI" || pvpNode.tagName === "A") {
        pvpNode.classList.remove("d-none");
      } else {
        pvpNode.disabled = true;
      }
    // break;

    case 4:
      // select
      try {
        if (pvpNode.tagName === "OPTION") {
          pvpNode.selected = true;
        } else if (pvpNode.tagName === "LI") {
          pvpNode.classList.remove("d-none");
        } else {
          switch (pvpNode.type) {
            case "checkbox":
              pvpNode.checked = true;
              break;
            default:
              break;
          }
        }
      } catch (e) {
        console.log("exception with pvpNode.tagName", e);
        console.log("PvpNode: ", pvpNode);
        console.log("Pvp: ", pvp);
      }
      break;
    
    case 5:
      try {
        if (pvpNode.classList.contains('select-many')) {
          // Select Many
          switch (pvpNode.type) {
            case "checkbox":
              pvpNode.checked = true;
              //pvpNode.disabled = true;
              break;
            default:
              break;
          }
        } else {
          console.info(`Unexpected pvp state=${pvp.state} for ${pvp.id}  for select one checkbox`);
        }
      } catch (e) {
        console.log("exception with pvpNode.tagName", e);
        console.log("PvpNode: ", pvpNode);
        console.log("Pvp: ", pvp);
      }
      break;

    default:
      console.info(`Unexpected pvp state=${pvp.state} for ${pvp.id}`);
  }

  // if searchable select - update state
  if (pvpNode.tagName === "OPTION") {
    if (pvpNode.parentNode.classList.contains("searchable")) {
      $(`#${pvpNode.parentNode.id}`).selectpicker("refresh");
    }
  }

  // if new display value received - set it
  for (let prop in pvp) {
    if (prop === "dvalue") {
      let value;
      if (pvpNode.dataset.type === "Number") {
        value = format(pvpNode, pvp.dvalue);
      } else {
        value = pvp.dvalue;
      }

      // IO and TEXTAREA
      if (pvpNode.type === "text" || pvpNode.type === "textarea") {
        pvpNode.value = value;

        // RANGE (slider)
      } else if (pvpNode.type === "range") {
        pvpNode.value = value;
        let out = pvpNode.parentNode.querySelector(".range-out");
        if (out) {
          out.innerHTML = value;
        }

        // IMAGE
      } else if (pvpNode.tagName === "IMG") {
        pvpNode.src = value;

        // OUTPUT BOX
      } else if (pvpNode.tagName === "DIV" && !pvpNode.classList.contains("custom-s")) {
        const re = /button/im;
        let isBtn = false;
        if (re.test(value)) {
          isBtn = true;
        }

        // this is block to handle IO pvp and NOT S delete all children first
        while (pvpNode.firstChild) {
          pvpNode.removeChild(pvpNode.firstChild);
        }

        if (pvpNode.classList.contains("vlookup")) {
          // need to fetch actual value from the server
          // based on returned pvp value
          if (pvp.dvalue.trim().length > 0) {
            (async () => {
              let payload = { q: pvp.dvalue };
              const { status, data } = await sendRequest(LOOKUP_URL, payload);
              if (status >= 200 && status < 400) {
                let fragment = document
                  .createRange()
                  .createContextualFragment(data.data);
                pvpNode.appendChild(fragment);
              }
            })();
          }
        } else {
          let fragment = document.createRange().createContextualFragment(value);
          pvpNode.appendChild(fragment);

          // need to re-register event listeners as we deleted all child nodes
          // and attached the new ones
          if (isBtn) {
            const exoMethods = Array.from(
              pvpNode.querySelectorAll("[data-event^='method']")
            );
            exoMethods.forEach((method) => {
              method.addEventListener("click", handleMethodClickEvent);
            });

            const exoEvents = Array.from(
              pvpNode.querySelectorAll("[data-event^='eventType']")
            );
            exoEvents.forEach((exoEvent) => {
              exoEvent.addEventListener("click", handleExodusClickEvent);
            });

            const pageNameEvents = Array.from(
              pvpNode.querySelectorAll("[data-event^='pageName']")
            );
            pageNameEvents.forEach((exoEvent) => {
              exoEvent.addEventListener("click", handlePageNameClickEvent);
            });
          }
        }
        
        // METER
      } else if (pvpNode.tagName === 'METER' || pvpNode.tagName === 'meter') {
        try {
          const meterAttrs = pvp.dvalue.split(':');
          if (meterAttrs[0]!=='') {
            pvpNode.setAttribute('value',meterAttrs[0]);
          }
          if (meterAttrs[1]!=='') {
            pvpNode.setAttribute('min',meterAttrs[1]);
          }
          if (meterAttrs[2]!=='') {
            pvpNode.setAttribute('max',meterAttrs[2]);
          }
          if (meterAttrs[3]!=='') {
            pvpNode.setAttribute('low',meterAttrs[3]);
          }
          if (meterAttrs[4]!=='') {
            pvpNode.setAttribute('high',meterAttrs[4]);
          }
          if (meterAttrs[5]!=='') {
            pvpNode.setAttribute('optimum',meterAttrs[5]);
          }
        } catch (e) {
          console.error(`Failed to updated 'Meter' for pvp=${pvp.id} and value=${pvp.dvalue}, reason: ${e}`)
        }

        // BUTTON
      } else if (pvpNode.tagName === "BUTTON") {
        // action for the button
        if (value.length > 0) {
          const z = value.split("|");
          while (pvpNode.firstChild) {
            pvpNode.removeChild(pvpNode.firstChild);
          }
          pvpNode.appendChild(
            document.createRange().createContextualFragment(z[0])
          );
          pvpNode.classList.remove("d-none");
          pvpNode.classList.remove("disabled");
          if (Boolean(z[1])) {
            pvpNode.dataset.event = z[1];
            // register listener for the button as it will be re-written
            if (z[1].startsWith("method")) {
              pvpNode.addEventListener("click", handleMethodClickEvent);
            } else if (z[1].startsWith("eventType")) {
              pvpNode.addEventListener("click", handleExodusClickEvent);
            } else if (z[1].startsWith("pageName")) {
              pvpNode.addEventListener("click", handlePageNameClickEvent);
            }
          } else {
            pvpNode.classList.add("disabled");
          }
        } else {
          pvpNode.classList.add("d-none");
        }

        // CHART
      } else if (
        pvpNode.tagName === "CANVAS" &&
        pvpNode.classList.contains("exo-chart")
      ) {
        pvpNode.dataset.series = value;
        updateChart(pvpNode.id);
      }
    }
  }

  pvpNode.dataset.state = pvp.state;
}

function updateSelectMany0(pvpNode) {
  const topNode = pvpNode.closest(".dropdown");
  updateSelectMany(topNode);
}

export function updateSelectMany(dropdownNode) {
  const inputs = Array.from($(dropdownNode).find("input[type=checkbox]"));
  let sCount = 0, selections = {};

  inputs.forEach((cb) => {
    // manual select badge color
    let badgeStyle = 'info';
    let title = 'Manual selection';
    if(cb.checked) {
      sCount++;
      // selections.push(cb.nextSibling.textContent);
      // autoselect: show different color badge
      if(cb.dataset.state === '2') {
        badgeStyle = 'secondary';
        title = 'Auto selection';
      } else if(cb.dataset.state === '5') {
        badgeStyle = 'danger';
        title = 'Confilicting manual selection';
      }
      selections[cb.nextSibling.textContent] = `${badgeStyle}|${title}`;

    }
  });

  // div to hold badges representing selections
  const selDiv = $(dropdownNode).find('.select-many-checked');
  selDiv.addClass("mt-1");
  selDiv.empty();
  if (sCount>0) {
    $(dropdownNode).children("button").html(`Selected ${sCount}`);
    
    for (const [text, style] of Object.entries(selections)) {
      const badge = style.split('|');
      selDiv.append(`<span class="badge badge-${badge[0]} text-truncate" style="max-width: 95%;" title="${badge[1]}">${text}</span>&nbsp;`);
    }
    // selections.forEach((s) => {
    //   selDiv.append(`<span class="badge badge-${badgeStyle} text-truncate" style="max-width: 95%;">${s}</span>&nbsp;`);
    // });
  } else {
    $(dropdownNode).children("button").html("None selected");
  }
}

/**
 *
 */
async function processServerMessages(messages) {
  if (messages && messages.length > 0) {
    // let msgModalBody = document.getElementById('msgModalBody');
    // let parser = new DOMParser();
    messages.forEach((msg, i) => {
      showMessage(msg.MESSAGE, i === 0);
      // try {
      //     // let m = parser.parseFromString(msg.MESSAGE, "text/html");
      //   let m = document.createTextNode(msg.MESSAGE);
      //   if (msgModalBody.childNodes.length > 0) {
      //     msgModalBody.apendChild(document.createElement("BR"));
      //   }
      //   msgModalBody.appendChild(m);
      // } catch(e) {
      //   console.log(`Failed to parse server message: ${e}`);
      // }
    });
    // $('#msgModal').modal({'backdrop' :false});
  }
}

/**
 *
 */
async function processTableData(tableData) {
  const tblIds = Object.keys(tableData);
  // console.log(`tblIds.length: ${tblIds.length}`);
  for (const tblId of tblIds) {
    // // console.log(`Processing DataTable ${tblId}`);
    // if (tableData[tblId].length === 0) {
    //   // console.info(`${tblId} has no rows`);
    //   continue;
    // }

    let row, i, j, k, col, tmpRow;
    let tblNode = document.getElementById(tblId);
    let rows = tableData[tblId];

    if (tblNode != null) {

      // skip updating hidden tables
      if (tblNode.dataset.blockHide === "2") {
        continue;
      }

      // console.log(`DeltaChangeHandler - processing DataTable ${tblId}`);
      let dt = $("#" + tblId).DataTable();
      dt.off("click");
      // dt.rows().remove(); // much slower than dt.clear() below
      dt.clear();
      if (rows !== null) {
        for (i = 0; i < rows.length; i++) {
          row = rows[i];
          j = 0;
          var dtrow = [];
          for (col in row) {
            if (col === "_id") {
              continue;
            }
            dtrow.push(row[col]);
          }
          dt.row.add(dtrow);
        }
      }
      dt.draw();
      // need this to show responsive expand/collapse controls after paging
      dt.on("page", dt.responsive.recalc);
      dt.on("order.dt", dt.responsive.recalc);

      // console.log('about to register click events with handleExodusClickEvent');
      // $(dt).off("click","[data-event]",handleExodusClickEvent);
      // dt.on("click", ".btn:not([href])", handleExodusClickEvent);
      dt.one("click", "[data-event^=eventType]", handleExodusClickEvent);
      dt.one("click", "[data-event^='method']", handleMethodClickEvent);
      dt.one("click", "[data-event^='pageName']", handlePageNameClickEvent);
      dt.one("click", "a, button, .nav-link, .btn", recordNavClick);
      // console.log('registered click events with handleExodusClickEvent');
      try {
        // dt.columns.adjust().responsive.recalc();
        dt.responsive.recalc();
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(`Table node for ${tblId} is NULL`);
    }
  }
}

async function processChart(tableData) {
  // console.log("Processing chart data");
  const chartIds = Object.keys(tableData);
  for (const chartId of chartIds) {
    // console.log(`Processing chart ${chartId}`);
    const chartCanvas = Array.from(document.querySelectorAll(`.${chartId}`));
    chartCanvas.forEach((charts) => {
      // console.log(`Processing chart ${charts.classList}`);
      let chartConfig = charts.dataset.chartConfig;
      // console.log(`Chart config: ${chartConfig}`);

      // Parse config
      const r = /\[([^\[^\]]+),?\]/g;
      let m;
      let generalConfig;
      let chartConfigPartial =  '';
      let first = true;
      while (m = r.exec(chartConfig)) {
        if(first) {
          generalConfig = m[0];
          first = false;
        } else {
          chartConfigPartial += m[0];
        }
      }

      // Parse series config
      const chartConfigDivided = Object.fromEntries(
        chartConfigPartial
          .slice(1, -1)
          .split("|")
          .map((item) => item.split(":"))
      );

      // Destructure all properties
      const {
        groupby,
        series,
        x,
        y,
        label,
        seriescolor,
        sortx,
        type,
        rel
      } = chartConfigDivided;

      let groupByArray = groupby.split(",");

      let relActual;
      if (rel !== undefined) {
        // console.log(`rel series pid: ${rel}`);
        const relSelector = document.querySelector(`#${rel}`);
        if (relSelector !== undefined && relSelector.selectedIndex > -1) {
          relActual = relSelector.value;
        } else {
          console.log(`Failed to find actual rel series name by element id=#${rel}`);
        }
        // console.log(`Relative series name: ${relActual}`);
      }

      // Initialize an empty output object
      const output = {};

      // Group data based on groupBy field in metadata
      tableData[chartId].forEach((item) => {
        let groupKey = "";
        groupByArray.forEach((gbKey) => {
          if(groupKey.length > 0) {
            groupKey += ":";
            groupKey += gbKey;
          } else {
            groupKey += gbKey;
          }
        });

        if (!output[groupKey]) {
          output[groupKey] = [];
        }
        output[groupKey].push(item);
      });

      // Initialize an empty array to store stripes
      const graphDataSeries = [];

      // Generate output table for each group
      for (const groupKey in output) {
        if (output.hasOwnProperty(groupKey)) {
          const groupData = output[groupKey];
          // Extract relevant data for each series within the group
          const seriesData = {};
          const rawSeriesData = {};
          groupData.forEach((item) => {
            let seriesKey = `serie:${item[series]}|bgcolor:${seriescolor.includes("#") ? seriescolor : item[seriescolor]}`;
            
            // draw rel series as line if applicable
            if(relActual !== undefined && relActual === item[series]) {
              seriesKey += "|type:line";
            }

            if(type && item[type]) {
              seriesKey += `${"|type:"+item[type]}`;
            }
            if (!seriesData[seriesKey]) {
              seriesData[seriesKey] = [];
            }

            if(relActual === undefined) {
              // Check if the data point is already present
              let dl = '';
              if (item[label]) {
                // need to have :: instead of : because the third attr is color of a data point
                dl += '::' + item[label];
              }
              
              const dataPoint = `data:${item[x]}:${item[y]}${dl}`; //+ label?':'+label:'';
              if (!seriesData[seriesKey].includes(dataPoint)) {
                seriesData[seriesKey].push(dataPoint);
              }
            } else {
              // for relative charts only
              let xKey = item[x];
              if (!rawSeriesData[xKey]) {
                rawSeriesData[xKey] = [];
              }
              rawSeriesData[xKey].push({series:item[series],seriesKey,y:item[y],color:'',label:item[label]!==undefined?item[label]:''}); 
            }
          });

          // for relative charts only
          if (relActual !== undefined) {
            // console.log(rawSeriesData);
            for (const [xPoint, series] of Object.entries(rawSeriesData)) {
              const relPoint = series.find((r) => r.series === relActual);
              if(relPoint !== undefined) {
                // console.log(`found rel point: ${relPoint.seriesKey}`);
                series.forEach((dp) => {
                  let relDataPoint = {};
                  const yRel = dp.y/relPoint.y;
                  let dl = '';
                  if (dp.label.length > 0) {
                    // need to have :: instead of : because the third attr is color of a data point
                    dl += '::' + dp.label;
                  }
                  const dataPoint = `data:${xPoint}:${yRel}${dl}`;
                  if (!seriesData[dp.seriesKey].includes(dataPoint)) {
                    seriesData[dp.seriesKey].push(dataPoint);
                  }
                });
              }
            }
          }
 
          let sortAsc = true ? sortx === "asc" : false;
          const stripe = Object.keys(seriesData)
            .map((seriesKey) => {
              const dataPoints = seriesData[seriesKey]
                .sort((a, b) => {
                  const xA = a.split(":")[1];
                  const xB = b.split(":")[1];

                  // Check if xA and xB are numbers
                  if (!isNaN(xA) && !isNaN(xB)) {
                    return sortAsc ? xA - xB : xB - xA;
                  }

                  // If xA and xB are not numbers, sort them as strings
                  return sortAsc ? xA.localeCompare(xB) : xB.localeCompare(xA);
                })
                .join("|");

              return `[${seriesKey}|${dataPoints}]`;
            })
            .join("");

          graphDataSeries.push(stripe);
        }
      }
      let graphDataSeriesString = graphDataSeries.join("");
      charts.dataset.series = generalConfig + graphDataSeriesString;
      updateChart(charts.id);
    });
  }
}

/**
 *
 */
function processBom(bomJson) {
  if (bomJson) {
    const exoContainer = document.querySelector(`.${g.exoContainer}`);
    exoContainer.dataset.bomId = bomJson.BOM_ID;
    exoContainer.dataset.bomName = bomJson.BOM_NAME;
  }
}

/**
 *
 */
function disablePvp(node, pvp) {
  // console.log(node);
  if (node.dataset.dt === "1") {
    node.disabled = true;

    if (node.classList.contains("custom-s")) {
      node.classList.add("disabled");
    }
  } else if (node.dataset.dt === "2") {
    switch (node.type) {
      // section may not be in use any longer
      // originally was used with custom-s
      case "section":
        node.classList.add("d-none");
        break;
      case "checkbox":
        // checkbox is groupped with label by parent div
        try {
          node.parentNode.classList.add("d-none");
        } catch (e) {}
        break;
      default:
        // console.log(`disabling+hiding node ${node.type}`);
        node.disabled = true;
        if (node.classList.contains("custom-s")) {
          if (node.classList.contains("d-block")) {
            node.classList.add("exo-block");
            node.classList.remove("d-block");
          } else if (node.classList.contains("d-inline-block")) {
            node.classList.add("exo-inline-block");
            node.classList.remove("d-inline-block");
          }
        }
        node.classList.add("d-none");
        break;
    }
  }
}

/**
 *
 */
function enablePvp(node, pvp) {
  if (node.dataset.dt === "1") {
    node.disabled = false;
    if (node.classList.contains("custom-s")) {
      node.classList.remove("disabled");
    }
  } else if (node.dataset.dt === "2") {
    switch (node.type) {
      case "section":
        node.classList.remove("d-none");
        break;
      case "checkbox":
        // checkbox is groupped with label by parent div
        try {
          node.parentNode.classList.remove("d-none");
          // if node was previously autoselected it has disabled attr set to true
          // flip it just in case
          node.disabled = false;
        } catch (e) {}
        break;
      default:
        node.disabled = false;
        node.classList.remove("d-none");
        if (node.classList.contains("custom-s")) {
          if (node.classList.contains("exo-block")) {
            node.classList.remove("exo-block");
            node.classList.add("d-block");
          } else if (node.classList.contains("exo-inline-block")) {
            node.classList.remove("exo-inline-block");
            node.classList.add("d-inline-block");
          }
        }
        break;
    }
  }
}
