/**
 * Loop through blocks in response. <br>
 * Create single blocks. <br>
 * Create Cells.<br>
 * bb = blockBody<br>
 * 
 * @module buildBlocks
 * @param {object}
 *          bFrag - HTML fragment where .exo-container exists
 * @returns {SingleBlock[]} - Array of HTML block elements.
 */

import buildCells from '../buildCells.js';

// new, testing
import StdBlock from './StdBlock';
import TabBlock from './TabBlock';
import OlBlock from './OlBlock';
import TblBlock from './TblBlock';
import TblChildBlock from './TblChildBlock';
import CrslBlock from './CrslBlock';
import AccrBlock from './AccrBlock';
import CardBlock from './CardBlock';
import FlipCardBlock from './FlipCardBlock';
import BomListBlock from './BomListBlock';
import BomSaveBlock from './BomSaveBlock';
import ConfigListBlock from './ConfigListBlock';

export default function buildBlocks(response, bFrag) {
  const domBlocks = [];
  response.blocks.forEach(function (block) {
    // Create block according to type, then place block according to type
    let singleBlock;
    
    if (block.type === 'TAB') {
      singleBlock = new TabBlock(block, bFrag);
      singleBlock.appendToNav();

    } else if (block.type === 'OL') {
      singleBlock = new OlBlock(block, bFrag);
      singleBlock.appendBlock();

    } else if (block.type === 'TBL' || block.type === 'BOMLI' || block.type === 'BOMLIPV') {
      singleBlock = new TblBlock(block, bFrag);
      singleBlock.appendBlock();
      
    } else if (block.type === 'TBLCHILD') {
      singleBlock = new TblChildBlock(block, bFrag);
      singleBlock.appendBlock();

    } else if (block.type === 'CAR') {
      singleBlock = new CrslBlock(block, bFrag);
      singleBlock.appendBlock();

    } else if (block.type === 'ACC') {
      singleBlock = new AccrBlock(block, bFrag);
      singleBlock.appendBlock();

    } else if (block.type === 'CRD') {
      singleBlock = new CardBlock(block, bFrag);
      singleBlock.appendBlock();
    
    } else if (block.type === 'FCRD') {
      singleBlock = new FlipCardBlock(block, bFrag);
      singleBlock.appendBlock();
    
    } else if (block.type === 'BOMLIST') {
      singleBlock = new BomListBlock(block, bFrag);
      singleBlock.appendBlock();
      
    } else if (block.type === 'BOMSAVE') {
      singleBlock = new BomSaveBlock(block, bFrag);
      singleBlock.appendBlock();
    
    } else if (block.type === 'CONFLIST') {
      singleBlock = new ConfigListBlock(block, bFrag);
      singleBlock.appendBlock();
      
    } else {
      // handles STD and FLOAT blocks
      singleBlock = new StdBlock(block, bFrag);
      singleBlock.appendBlock();
    }

    if (block.type !== 'TBL' && block.type !== 'TBLCHILD') {
      buildCells(block, singleBlock.bb); // attach to body
    }

    domBlocks.push(singleBlock);
  });

  return domBlocks;
}
