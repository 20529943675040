/** 
 *@class CrslBlock 
 */
import { global as g } from '../../globals.js';
import StdBlock from './StdBlock.js';

export default class CrslBlock extends StdBlock {

  constructor(block, bFrag) {
    super(block, bFrag);
    const { hide, tooltip } = this.block;

    this.li = this.createLi();//header
    this.crslItem = this.createInnerItem();

    if (hide) {
      this.bh.classList.add('invisible');
      this.bb.classList.add('invisible');
    }

    g.addTooltip(this.bh, this.block);
    // this is wrong as may have multiple carousels on one page
    // CrslBlock.count++;
  }

  /** 
   * Creates li
   * @memberof CrslBlock#  
   */
  createLi() {
    const { id, parentLoc } = this.block;

    const li = document.createElement('li');
    li.dataset.target = `#carousel-${parentLoc}`;//target the id

    // this is wrong as may have multiple carousels on one page
    // li.dataset.slideTo = CrslBlock.count;

    // console.log(`CrslBlock.count: ${CrslBlock.count}`);
    
    // this is wrong as may have multiple carousels on one page
    // if (CrslBlock.count === 0)
    //   li.classList.add('active');

    return li;
  }

  createInnerItem() {
    const { id } = this.block;

    const crslItem = document.createElement('div');
    const crslCaption = document.createElement('div');

    crslCaption.classList.add('carousel-caption', 'd-none', 'd-md-block');

    crslItem.classList.add('carousel-item');
    // this is wrong as may have multiple carousels on one page
    // if (CrslBlock.count === 0)
    //   crslItem.classList.add('active');

    crslCaption.appendChild(this.bh);
    // crslCaption.textContent = (this.bh.querySelector('div').textContent);
    // crslCaption.style.color = 'black';

    crslItem.appendChild(this.bb);
    crslItem.appendChild(crslCaption);

    return crslItem;
  }

  /** 
  * On parent's body (#blockb-parentLoc), uses an existing nav or creates one 
  * Invoked by buildBlocks.js
  * @memberof CrslBlock#  
  */
  appendBlock() {
    const { parentLoc, id } = this.block;

    // if !navTabPulldown
    let crsl = this.bFrag.querySelector(`#blockb-${parentLoc} div.carousel`);

    if (crsl) {
      let ol = crsl.querySelector('.carousel-indicators');
      let innerCrsl = crsl.querySelector('.carousel-inner');

      ol.appendChild(this.li);
      // if (ol.childElementCount == 1) {
      //   this.li.classList.add('active');
      // }

      // mark active item and add targets
      if (ol.hasChildNodes()) {
        let children = ol.childNodes;
        for (let i = 0; i < children.length; i++) {
          if (i === 0) {
            children[i].classList.add('active');
          }
          children[i].dataset.slideTo = `${i}`;
        }
      }

      innerCrsl.appendChild(this.crslItem);

      // mark active div
      if (innerCrsl.hasChildNodes()) {
        let children = innerCrsl.childNodes;
        for (let i = 0; i < children.length; i++) {
          // console.log("carousel-inner node: "+i);
          // console.log(children[i]);
          if (i === 0) {
            children[i].classList.add('active');
            break;
          }
        }
      }

    } else { //No crsl
      const crslParent = this.bFrag.querySelector(`#blockb-${parentLoc}`);

      const crsl = document.createElement('div');
      const ol = document.createElement('ol');
      const innerDiv = document.createElement('div');

      crsl.appendChild(ol);
      crsl.appendChild(innerDiv);
      crslParent.appendChild(crsl);

      ol.appendChild(this.li);
      innerDiv.appendChild(this.crslItem);

      crsl.id = `carousel-${parentLoc}`;
      crsl.classList.add('carousel', 'slide');
      // crsl.dataset.interval = 3000;//set on buildPage after it is ready
      // crsl.dataset.ride = 'carousel';
      crsl.dataset.ride = 'false';
      crsl.dataset.interval = false;

      ol.classList.add('carousel-indicators');
      innerDiv.classList.add('carousel-inner');
      crsl.appendChild(this.createControls());

      g.isTopLvlContainer = true;
    }
  }

  /** 
  * Creates "next" and "previous" controls on carousel
  * @memberof CrslBlock#  
  */
  createControls() {
    const { parentLoc } = this.block;

    const ctrlsFrag = document.createDocumentFragment();

    const prev = document.createElement('a');
    const next = document.createElement('a');
    const prevIcon = document.createElement('span');
    const nextIcon = document.createElement('span');
    const srPrev = document.createElement('span');
    const srNext = document.createElement('span');

    prev.classList.add('carousel-control-prev');
    prev.href = `#carousel-${parentLoc}`;
    prev.dataset.slide = 'prev';
    prev.setAttribute('role', 'button');

    next.classList.add('carousel-control-next');
    next.href = `#carousel-${parentLoc}`;
    next.dataset.slide = 'next';
    next.setAttribute('role', 'button');

    prevIcon.classList.add('carousel-control-prev-icon');
    prevIcon.setAttribute('aria-hidden', 'true');

    nextIcon.classList.add('carousel-control-next-icon');
    nextIcon.setAttribute('aria-hidden', 'true');

    srPrev.classList.add('sr-only');
    srPrev.textContent = 'Previous';

    srNext.classList.add('sr-only');
    srNext.textContent = 'Next';

    prev.appendChild(prevIcon);
    prev.appendChild(srPrev);

    next.appendChild(nextIcon);
    next.appendChild(srNext);

    ctrlsFrag.appendChild(prev);
    ctrlsFrag.appendChild(next);

    return ctrlsFrag;
  }
}

CrslBlock.count = 0;