import 'core-js/features/array/from';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/String.prototype.includes';
import 'mdn-polyfills/Node.prototype.remove';
import 'isomorphic-fetch';
import "classlist-polyfill";// using this one, mdn's version did not work
import es6Promise from "es6-promise";
es6Promise.polyfill();

import openPage from './page-builder';

window.exo = {
	init: (params) => {
		openPage(params);
	}
}
