import addControlType from './addControlType.js';
import { global as g } from '../globals.js';
import { closeChildPage, copyToClipboard} from './eventHandlers.js';

/**
 * pvps = parameter value pairs
 * 
 * @module addPivsAndPvps
 * 
 * @param {object}
 *          response
 * @param {object}
 *          bFrag
 */


export default (response, bFrag) => {

  // ==== ADD STATIC TEXT TO CELLS
  addStaticText(response, bFrag);
  
  // ==== ADD PIDS AND PVPS TO THE CELLS
  addParameters(response, bFrag);

  // ==== ADD CLOSED PAGES TO THE CHILD PAGES TBL
  addChildPages(response, bFrag);
  
}

/**
 * 
 * @param response
 * @param bFrag
 * @returns
 */
function addStaticText(response, bFrag) {
  response.staticTextList.forEach(function (st, i) {
    let cellIdArray = st.cellId.split(',');
    let targetCellId = "c-" + cellIdArray.join('\\.');
    let targetCell = bFrag.querySelector("#" + targetCellId);
    
    // // if(i===0) {
    //   console.log('Add Static Text START')
    //   console.log(st);
    //   console.log(bFrag);
    //   console.log(targetCell);
    //   console.log('Add Static Text FINISH')
    // // }

    if (targetCell) {
      targetCell.innerHTML = st.staticContent;
    }
  });
}

/**
 * 
 * @param response
 * @param bFrag
 * @returns
 */
function addParameters(response, bFrag) {
  response.parameters.forEach(function (par, i) { 

    // if (par.hide) console.log(par);

    // tcRef = targetCellReference: stores LAST FOUND target cell in order to
    // find the parent
    let ctTargetCellId, msTargetCellId, ttTargetCellId, lbTargetCellId, targetCell, tcRef;

    if (par.controlLoc) {
      // to escape ".", double scaping (\\.) , one for JS and another for CSS,
      // querySelector will not work if not so.
      ctTargetCellId = "c-" + par.controlLoc.split(',').join('\\.');// controlType
      targetCell = bFrag.querySelector("#" + ctTargetCellId);

      if (targetCell) {
        addControlType(par, targetCell);
        tcRef = targetCell;

        // if hide, set PID?? to invisible; set here to ensure correct cell
        if (par.hide && !par.hideType) {
          targetCell.classList.add('invisible');
        }
      }
    }

    if (par.mandatory > 0 && par.mandatoryLoc) {
      msTargetCellId = "c-" + par.mandatoryLoc.split(',').join('\\.');// mandatorySymbol
      targetCell = bFrag.querySelector("#" + msTargetCellId);

      if (targetCell) {
        targetCell.innerHTML = `<div class="col-form-label-sm">${response.page.mandatorySymbol}</div>`;
        tcRef = targetCell;
      }
    }

    if (par.tooltipLoc) {

      ttTargetCellId = "c-" + par.tooltipLoc.split(',').join('\\.');// toolTip
      targetCell = bFrag.querySelector("#" + ttTargetCellId);

      if (targetCell) {
        let tooltipTrigger = response.page.tooltipTrigger;
        targetCell.innerHTML = `<div class="col-form-label-sm"><a tabIndex="0">${response.page.tooltipSymbol}</a></div>`;
        // needs to be added before using
        // let ttElm = targetCell.querySelector('img'); 
        let ttElm = targetCell.querySelector('a'); 
        ttElm.dataset.role="tooltip";
        ttElm.dataset.title = par.id;
        tcRef = targetCell;
        g.pvpTooltip(ttElm, par, tooltipTrigger);
      }
    }

    if (par.labelLoc) {

      lbTargetCellId = "c-" + par.labelLoc.split(',').join('\\.');// label
      targetCell = bFrag.querySelector("#" + lbTargetCellId);

      if (targetCell) {
        targetCell.innerHTML = `<div class="col-form-label-sm">${par.label}</div>`;
        tcRef = targetCell;
      }
    }

    if (tcRef) {
      if (par.hide && par.hideType) { // hide parent row

        let parent = tcRef.parentElement;

        while (!parent.dataset.rowLocation && !parent.classList.contains(g.exoContainer)) {
          parent = parent.parentElement;
        }

        // check that loop ended because parent is found, and not because parent
        // is .exo-container
        if (parent.dataset.rowLocation) {
          parent.classList.add('d-none');
        }
      }
    }
  });
}

/**
 * 
 * @param response
 * @param bFrag
 * @returns
 */
function addChildPages(response, bFrag) {
  let tbl, tbody, {page} = response;
  try {
    tbl = bFrag.querySelector('[id^="tblchild"]');
    if(tbl) {
      tbody = tbl.getElementsByTagName('TBODY')[0];
// console.log(page.closedPages);
      page.closedPages.forEach(function (p, i) {
        let tr = document.createElement('TR');
        let td = document.createElement('TD');
        let link = document.createElement('A');
        link.dataset.event = `eventType=3&pageId=${p.pageId}&pageViewSeqId=${p.pageViewSeqId}&defaultFlag=1`;
        link.href = '#';
        link.appendChild(document.createTextNode(p.pageName));
        td.appendChild(link);
        tr.appendChild(td);
        // if table has two columns add delete link+image
        if (tbody.firstElementChild.childElementCount === 2) {
          let td = document.createElement('TD');
          let link = document.createElement('A');
          let img = document.createElement('IMG');
          link.dataset.event = `pageId=${p.pageId}&pageViewSeqId=${p.pageViewSeqId}&defaultFlag=1`;
          link.href = '#';
          img.src = `${g.baseUrl}/resources/ico/delete.png`;
          img.alt = "Delete";
          link.appendChild(img);
          td.appendChild(link);
          tr.appendChild(td);
          link.addEventListener("click", closeChildPage);
        } else if(tbody.firstElementChild.childElementCount === 3) {
          // delete button
          let td = document.createElement('TD');
          let link = document.createElement('A');
          let img = document.createElement('IMG');
          link.dataset.event = `pageId=${p.pageId}&pageViewSeqId=${p.pageViewSeqId}&defaultFlag=1`;
          link.href = '#';
          img.src = `${g.baseUrl}/resources/ico/delete.png`;
          img.alt = "Delete";
          link.appendChild(img);
          td.appendChild(link);
          tr.appendChild(td);
          link.addEventListener("click", closeChildPage);
          // copy link
          let td2 = document.createElement('TD');
          let link2 = document.createElement('A');
          let img2 = document.createElement('IMG');
          img2.src = `${g.baseUrl}/resources/img/copy.png`;
          img2.alt = "Copy to clipboard";
          link2.href = '#';
          link2.dataset.copy = `${window.location.protocol}//${window.location.hostname}${g.baseUrl}/page?eventType=3&targetPageId=${p.pageId}&pageViewSequenceId=${p.pageViewSeqId}&defaultFlag=1`;
          link2.appendChild(img2);
          td2.appendChild(link2);
          tr.appendChild(td2);
          link2.addEventListener("click", copyToClipboard);
        }
        tbody.appendChild(tr);
      });
    }
  } catch(e) {
    console.log(e)
  }
}

/**
 * 
 * @param par
 * @param targetCell
 * @returns
 */
function isParHidden(par, targetCell) {
  if (par.hide) {
    targetCell.classList.add('invisible');
  }

  // for testing purposes, adding hide and hideType as dataset
  targetCell.dataset.hide = par.hide;
  targetCell.dataset.hideType = par.hideType;
}
