export const global = {
  dependencies: { jsZip: "https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.js" },

  baseUrl: "", //"/exodus", // "",

  isTopLvlContainer: true,

  // read-only properties
  get exoContainer() {
    return "exo-container";
  },
  get container() {
    return "container-fluid";
  },

  addTooltip: function (elm, thisBlock) {
    let { ttCssId, tooltip } = thisBlock;

    if (tooltip && tooltip !== "NIL") {
      // $(elm) converts DOM element to jQuery element
      $(elm).popover({
        trigger: "focus hover",
        placement: "auto",
        html: true,
        // title: titleDiv,
        content: tooltip,
        template: `
          <div class="popover ${ttCssId}" role="tooltip">
            <div class="arrow"></div>
            <div class="popover-body"></div>
          </div>
            `,
        // <h3 class="popover-header"></h3>
      });
    }
  },

  pvpTooltip: function (elm, par, tooltipTrigger) {
    let { cssId, tooltip } = par;

    if (tooltip && tooltip !== "NIL") {
      // $(elm) converts DOM element to jQuery element
      $(elm).popover({
        // trigger: tooltipTrigger,
        trigger: "focus hover",
        placement: "auto",
        html: true,
        // title: titleDiv,
        content: tooltip,
        template: `
          <div class="popover" role="tooltip">
            <div class="arrow"></div>
            <div class="popover-body"></div>
          </div>`,

        // template below was breaking display when pm puts form-control-sm or similar
        // class into css field of dgen
        // template: `
        // <div class="popover ${cssId}" role="tooltip">
        // <div class="arrow"></div>
        // <div class="popover-body"></div>
        // </div>`
        // <h3 class="popover-header"></h3>
      });
    }
  },
};
