// import resolveWidth from './build-blocks/resolveWidth.js';

/**
 * Builds cells and rows
 * 
 * @param {*} block
 * @param {*} bb
 */


export default function buildCells(block, bb) {
  // bb = #blockb-${block.id}
  let lastRowId, row;
  
  // build cells
  // sort cells to avoid placing of cell 10 after cell 1 or row 10 after row 1
  if (block.cells instanceof Array) {
    block.cells.sort(function (a, b) {
      return Number(a.cellId.split(',')[1]) - Number(b.cellId.split(',')[1])
        || Number(a.cellId.split(',')[2]) - Number(b.cellId.split(',')[2]);
    });
  }
  // else {
  // }

  block.cells.forEach(function (cell) {
    try {
      // if width is defined for the cell then process it
      if (cell.widthFraction) {
        let cellId = cell.cellId.split(',');
        let col = document.createElement('div');
        let cellContent = document.createElement('div');
  
        if (row === undefined || cellId[1] !== lastRowId) {
          row = document.createElement('div');
          row.className = 'row';
          if (cell.rowCssId && cell.rowCssId != 'NIL') {
            row.classList.add(cell.rowCssId);
          }
          lastRowId = cellId[1];
          bb.appendChild(row);
          // used in addContent.js.
          // sets attr data-row-location="[blockid]-[rowid]" on a row div
          row.dataset.rowLocation = cellId.slice(0,2).join("-");
        }
        // col.id = 'c-' + cellId.join('.');
        // col.className = 'col-' + resolveWidth(cell.widthFraction);
        col.className = `col-${cell.widthFraction}`;
  
        cellContent.id = 'c-' + cellId.join('.');
        cellContent.classList.add('exo-cell');
        if(cell.cssId && cell.cssId.length > 0) {
          cellContent.classList.add(cell.cssId);
        } else {
          // console.info(`Undefined cssId for cell: ${cell.cellId}`);
        }
  
        col.appendChild(cellContent);
        row.appendChild(col);
      }
    } catch(e) {
      console.error(`Failed to build cell: ${cell.cellId}`, e);
    }
  });
}

function shouldRowHide(block, row) {

  // if(block.)

}
