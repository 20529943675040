/**
 * 
 */
export function format(element, value, locale='en-US') {
  // exo-number-0d-USD
  // exo-number-0d-PCT
  // exo-number-2d
  // exo-number-0d-PX-...
  // exo-number-0d-SX-...
  const classes = element.classList;
  const number = Number(value);
  // { style: 'currency', currency: 'EUR' }
  let opts = {}, prefix='', suffix='', px = false, sx = false;
  Array.from(classes).forEach(cls => {
    // use only classes starting with 'exo-' to apply formatting
    if (cls.startsWith('exo-')) {
      // console.log(`Found exo class ${cls}`);
      // TODO make the below replace more generic
      const f = cls.replace('exo-number-','');
      let c = f.split('-');
      c.forEach((v, i) => {
        switch (i) {
          case 1:
            switch (v.toUpperCase()){
              case 'PCT':
                opts.style = 'percent';
                opts.currencyDisplay = 'symbol';
                break;
              case 'PX':
                px = true;
                break;
              case 'SX':
                sx = true;
                break;
              default:
                opts.style = 'currency';
                opts.currency = v.toUpperCase();
            }
            break;
          case 2:
            if (px) {
              prefix = v;
            } else if (sx) {
              suffix = v;
            }
            break;
          default:
            const decimals = v.replace('d','');
            opts.style = 'decimal';
            try {
              let minfd = Number(decimals);
              if (!isNaN(minfd)) {
                opts.minimumFractionDigits = minfd;
                opts.maximumFractionDigits = minfd;
              }
            } catch(e){}
        }
      });
    }
  });
  return value===""?"":`${prefix}${new Intl.NumberFormat(locale, opts).format(number)}${suffix}`;
}