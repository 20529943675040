/**
 * Creates Table blocks. <br>
 * bb = blockBody, bh = blockHeader, fullB = fullBlock, bWrap = blockWrapper
 * 
 * @class TblBlock
 * @extends StdBlock
 * @param {object}
 *          block
 */

import TblBlock from './TblBlock.js';

export default class BomListBlock extends TblBlock {

  constructor(block, bFrag) {
    super(block, bFrag);
  }


  // override method on prototype
  attachListeners() {
    const { id, tblSearch, tblPager, tblScrollX , numCols, cells } = this.block;
    const table = this.table;
    
    $(document).ready(function() {
      let columnDefs = [];
      const tableId = `#tbl-${id}`;

      let rowH = table.querySelector('thead tr:nth-child(1)');
      let row1 = table.querySelector('tbody tr:nth-child(1)'), selectIdx = -1, dateIdx = -1;
      let row2 = table.querySelector('tbody tr:nth-child(2)');
      let row3 = table.querySelector('tbody tr:nth-child(3)');
      let row4 = table.querySelector('tbody tr:nth-child(4)');

      // iterate all cells on the second row and find what column 
      // has input for selecting a row (bom)
      for(let i = 0; i < numCols; i++) {
        if (row1.childNodes[i].innerHTML.indexOf('SELECT') === 0) {
          // add this class in case we need to do someting with this col later
          rowH.childNodes[i].classList.add('col-bom-select');
          selectIdx = i;
        } else if (row1.childNodes[i].innerHTML.indexOf('DATE') >= 0) {
          rowH.childNodes[i].classList.add('col-bom-date');
          dateIdx = i;
        }
      }

      // grab second row of cells as they contain cssId for data rows and width codes
      // width fraction can not have responsive trigger in it - just number
      for(let i = numCols, j = 0; i < numCols*2; i++, j++) {
        let w = cells[i].widthFraction;

        // if fraction contains responsive trigger - chop it off
        if (w.indexOf('-') > -1) {
          w = w.substr(w.indexOf('-')+1);
        }

        let columnDef = {
          targets: j,
          createdCell:  function (td, cellData, rowData, row, col) {$(td).addClass(cells[i].cssId)}
        };

        // add select input to the column
        if (j === selectIdx) {
          // columnDef.createdCell = function (td, cellData, rowData, row, col) {$(td).addClass(cells[i].cssId).append("<input type='radio' name='rbselect' value='foo' class='bom-select'/>")};
          columnDef.render = function ( data, type, row, meta ) {
            return "<input id='"+row[0]+"' type='checkbox' name='cbselect' value='' class='bom-select'/>";
          }
          columnDef.orderable = false;
          columnDef.searchable = false;
        } 
        else if (j === dateIdx) {
          columnDef.render = function(data) {
            return new Date(data).toLocaleString()
            // return moment(data).format('MM/DD/YYYY hh:mm:ss A');
          };
          // below line breakes sorting
          // columnDef.type = "date";
        }

        // the current column is hidden with either "none" or "never" class
        if(w!=='0') {
          columnDef.width = `${w*100/24}%`;
        } 
        // else {
        //   columnDef.orderable = false;
        //   columnDef.searchable = false;
        //   columnDef.visible = false;
        // }
        columnDefs.push(columnDef);
      }

      let dataOrder = [];
      for(let i = 0; i < numCols; i++) {
        // add responsive classes defined on row 4 
        // to the corresponding column headers
        if (row4.childNodes[i].innerHTML.length > 0) {
          rowH.childNodes[i].classList.add(row4.childNodes[i].innerHTML);
        }

        // buid data-order object to set it to the table data-order attribute
        // data-order="[[ 1, &quot;asc&quot; ]]"
        if (row3.childNodes[i].innerHTML.length > 0) {
          try {
            let order = parseInt(row3.childNodes[i].innerHTML);
            if (order > 0) {
              let direction = row2.childNodes[i].innerHTML;
              direction = direction.length>0?direction.toLowerCase():'asc';
              let colOrder = [i,direction];
              dataOrder.push(colOrder);
            }
          } catch(e) {
            console.log(`Failed to set sort order for datatable ${id}, reason: ${e}`);
          }
        }
      } 

      if (dataOrder.length>0) {
        table.dataset.order = JSON.stringify(dataOrder);
      }

      // remove all rows from tbody as we needed them for configuration only
      let tbody = table.querySelector('tbody');
      while (tbody.firstChild) {
        tbody.removeChild(tbody.firstChild);
      }

      // existing datatable can not be reinitialized. 
      // proceed only if datatable does not exist yet
      if (!$.fn.DataTable.isDataTable(tableId)) {
        const dtTable = $(tableId);
        
        const dt = dtTable.DataTable({
          columnDefs: columnDefs,
          searching: tblSearch===0?false:true,
          paging: tblPager===0?false:true,
          scrollX: tblScrollX===0?false:true,
          dom: '<"top"f<"clear">>rt<"bottom row"<"dt-f-l col-sm-6"l><"dt-f-c col-sm-12"p><"dt-f-r col-sm-6"i><"clear">>'
        });

        // this is required because on page load the Save Bom modal block
        // is not visible and responsive datatable renders all columns, 
        // including the ones marked as "never"
        $(dtTable).closest('.modal').on('shown.bs.modal', function () {
          try {
            dt.responsive.recalc();  
          } catch (error) {
            console.log(error);
          }
        })

        // Handle click on checkbox
        $(dtTable).on('change', 'input[type="checkbox"]', function(e){
          // store the last checked input id
          let bomId = $(dtTable).data('bomid');
          
          // if bomid exists and not equal to the id of the input clicked on
          if (bomId !== undefined && bomId !== this.id) {
            $(`${tableId} input#${bomId}`).prop("checked",false)
              .closest('tr').removeClass('selected');
          }
                    
          // get current row
          let $row = $(this).closest('tr');
          // Get row data
          let data = dt.row($row).data();

          // save id of the bom on the table level
          $(dtTable).data('bomid',data[0]);

          // need to address fixed position of CONFIGNAME and CONFIGID in the config list table
          if(this.checked){
            $row.addClass('selected');
            // Get bom name and set it to the bom pBOMName input box
            $('#pConfigName_1').val(data[1]);
            $('#pConfigID_1').val(data[0]);
          } else {
            $row.removeClass('selected');
            $('#pConfigName_1').val("");
            $('#pConfigID_1').val("");
          }

          // Prevent click event from propagating to parent
          e.stopPropagation();
        });
      } 
    });
  }
}
