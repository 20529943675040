/**
 * Creates a single block <br>
 * bb = blockBody, bh = blockHeader
 * 
 * @class OlBlock
 * @param {object}
 *          block
 */

// import resolveWidth from './resolveWidth.js';
import { global as g } from '../../globals.js';

export default class OlBlock {// STD Block

  constructor(block, bFrag) {
    this.block = block;
    this.bFrag = bFrag;

    this.modalCol = this.createModalCol();
    this.bh = this.buildBlockH();
    this.bb = this.buildBlockB();
    this.bf = this.buildBlockF();

    // assigned in buildModal()
    this.dialogDoc;
    this.modalContent;
    this.fullModal = this.buildModal();
    this.fullModal.dataset.blockType = this.block.type;

    // if (this.block.hide) {
    //   this.modalCol.classList.add('invisible');
    // }
  }

  /**
   * Creates modal column and trigger button Holds button and block modalCol >
   * triggerButton + modal
   * 
   * @memberof OlBlock#
   * 
   */
  createModalCol() {

    let { id, label, navCssId, breakpoint, width, hide } = this.block;
    const modalCol = document.createElement('div');

    if (width !== 'NIL') {
      modalCol.classList.add(`col-${breakpoint}-${width}`);
    } else {
      modalCol.classList.add(`col-${breakpoint}`);
    }

    if (!hide) {
      const btn = document.createElement('button');
      btn.type = 'button';
      btn.classList.add('btn');
      if (!btn.classList.contains('btn-primary') && !btn.classList.contains('btn-secondary') &&
        !btn.classList.contains('btn-success') && !btn.classList.contains('btn-danger') &&
        !btn.classList.contains('btn-warning') && !btn.classList.contains('btn-info') &&
        !btn.classList.contains('btn-light') && !btn.classList.contains('btn-dark') &&
        !btn.classList.contains('btn-link')) {
        btn.classList.add('btn-primary');
      }

      try {
        if (navCssId && navCssId !== 'NIL') {
          const cls = navCssId.split(" ");
          cls.forEach(cl => {
            if (cl.length > 0 && cl !== 'NIL') {
              btn.classList.add(cl);
            }
          });
        }
      } catch (e) {
        console.log("Failed to add classed to OL nav.", e);
      }

      btn.setAttribute('data-toggle', "modal");
      btn.setAttribute('data-target', `#block-${id}`);
      btn.textContent = label;
      g.addTooltip(btn, this.block);
      modalCol.appendChild(btn);
    }

    return modalCol;
  }

  /** @memberof OlBlock# */
  buildBlockH() {
    // modal header
    const { id, label, headerCssId, olCloseContent } = this.block;

    const modalHeader = document.createElement('header');
    const h5 = document.createElement('h5');
    const headerBtn = document.createElement('button');
    const span = document.createElement('span');

    modalHeader.id = `blockh-${id}`;
    modalHeader.classList.add('modal-header');
    if (headerCssId)
      modalHeader.classList.add(headerCssId)

    h5.classList.add('modal-title');
    if (label) {
      h5.textContent = label;
    }

    headerBtn.type = 'button';
    headerBtn.classList.add('close');
    headerBtn.setAttribute('data-dismiss', 'modal');

    let closeControls = olCloseContent;
    if (olCloseContent.length > 0) {
      closeControls = olCloseContent.split('|');
    }
    headerBtn.setAttribute('aria-label', 'Close');

    span.setAttribute('aria-hidden', 'true');
    // top right coner of the overlay
    span.appendChild(document.createRange().createContextualFragment(closeControls[0]));

    headerBtn.appendChild(span);

    modalHeader.appendChild(h5);
    modalHeader.appendChild(headerBtn);

    return modalHeader;
  }

  /**
   * Creates the modal, modal dialog, and modal content.
   * 
   * @memberof OlBlock#
   */
  buildBlockB() {
    // modal body
    const { id, bodyCssId } = this.block;
    const bb = document.createElement('div');

    bb.id = `blockb-${id}`;
    bb.classList.add('modal-body');

    if (bodyCssId)
      bb.classList.add(bodyCssId);

    return bb;
  }

  buildBlockF() {
    const { navCssId, olCloseContent } = this.block;
    
    const footer = document.createElement('footer');
    const closeBtn = document.createElement('button');

    footer.classList.add('modal-footer');

    closeBtn.type = 'button';
    closeBtn.classList.add('btn', 'btn-secondary');
    closeBtn.setAttribute('data-dismiss', 'modal');
    
    let closeControls = olCloseContent;
    if (olCloseContent.length > 0) {
      closeControls = olCloseContent.split('|');
    }
    if (closeControls.length > 1) {
      closeBtn.textContent = closeControls[1];
    } else {
      closeBtn.textContent = "Close";
    }

    // if (navCssId && navCssId !== 'NIL')
    //   closeBtn.classList.add(navCssId);

    // //====Will use later
    // const saveBtn = document.createElement('button');
    // saveBtn.type = 'button';
    // saveBtn.classList.add('btn', 'btn-primary');
    // saveBtn.textContent = 'Save changes';
    // footer.appendChild(saveBtn);

    footer.appendChild(closeBtn);

    return footer;
  }

  /** @memberof OlBlock# */
  buildModal() {

    // section[role:dialog]>[role:document]>modalContent>header+body+footer

    const { id, cssId } = this.block;

    const fullModal = document.createElement('section');
    const dialogDoc = document.createElement('div');
    const modalContent = document.createElement('div');
    const comment = document.createComment(`BLOCK ${id} starts on section right above.`);

    fullModal.id = `block-${id}`;
    // block-wrapper must be first class
    fullModal.classList.add(`block-wrapper`, 'modal', 'fade');
    fullModal.setAttribute('tabindex', '-1');
    fullModal.setAttribute('role', 'dialog');
    fullModal.setAttribute('aria-labelledby', `block-${id}`);
    fullModal.setAttribute('aria-hidden', 'true');

    dialogDoc.classList.add('modal-dialog', 'modal-dialog-centered', 'modal-lg');
    dialogDoc.setAttribute('role', 'document');

    modalContent.classList.add('modal-content');

    if (cssId) {
      modalContent.classList.add(cssId);
    }

    fullModal.appendChild(comment);
    fullModal.appendChild(dialogDoc);
    dialogDoc.appendChild(modalContent);

    this.dialogDoc = dialogDoc;
    this.modalContent = modalContent;

    return fullModal;
  }

  appendBlock() {
    const { parentLoc } = this.block;

    this.modalContent.appendChild(this.bh);
    this.modalContent.appendChild(this.bb);
    this.modalContent.appendChild(this.bf);
    this.modalCol.appendChild(this.fullModal);

    if (parentLoc) {
      // if there is a row in parent,use it, else create one
      let parentRow = this.bFrag.querySelector(`#blockb-${parentLoc} > .row`);
      if(!parentRow) {
        parentRow = document.createElement('div');
        parentRow.classList.add('row');
        this.bFrag.querySelector(`#blockb-${parentLoc}`).appendChild(parentRow);
      }

      parentRow.appendChild(this.modalCol);
      g.isTopLvlContainer = true;

    } else {
      if (g.isTopLvlContainer) { // if no parent, and g.isTopLvlContainer
        const container = createContainer(this.modalCol, this.fullModal);
        this.bFrag.querySelector(`.${g.exoContainer}`).appendChild(container);
        g.isTopLvlContainer = false;

      } else {
        const containerRow = this.bFrag.querySelector(`.${g.exoContainer} .${g.container}:last-child > .row`);
        containerRow.appendChild(this.modalCol);
      }
    }
  }

  /** @memberof OlBlock# */
  isCollapsable() {

    if (this.block.collapsable) {

      const bh = this.bh;
      const bb = this.bb;
      const rowCol = bh.querySelector('.col');

      bh.setAttribute('data-toggle', "collapse");
      bh.setAttribute('data-target', `#${bb.id}`);
      bh.setAttribute('aria-expanded', "false");
      bh.setAttribute('aria-controls', `${bb.id}`);
      bb.classList.add('collapse');

      if (this.block.initState === 'E') {
        bb.classList.add('show');
      }
    }
  }
}

function createContainer(modalCol) {

  const container = document.createElement('div');
  const row = document.createElement('div');

  container.classList.add(`${g.container}`);
  row.appendChild(modalCol);
  container.appendChild(row);

  return container;
}
