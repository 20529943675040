/**
 * Creates request using fetch Maps params to new request format ??? need
 * clarification
 * 
 * @function
 * @param {object}
 *          params - Passed by ???
 */

// import "isomorphic-fetch";
// import es6Promise from "es6-promise";
// es6Promise.polyfill();
import { global as g } from '../globals.js';

// export const URL_BASE = '/exodus'; // '';
export const PAGE_OPEN_URL = `${g.baseUrl}/eventhandler`;
export const PAGE_CLOSE_URL = `${g.baseUrl}/eventhandler`;
export const SELECT_URL = `${g.baseUrl}/eventhandler/select`;
export const DO_URL = `${g.baseUrl}/eventhandlerdo`;
export const CHANNEL_URL = `${g.baseUrl}/channel`;
export const CLICK_URL = `${g.baseUrl}/click`;
export const SEARCH_URL = `${g.baseUrl}/search`;
export const LOOKUP_URL = `${g.baseUrl}/lookup`;

export default async function sendRequest(url, params) {
  let requestJson = {};

  try {
    const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': document.head.querySelector("[name='_csrf'][content]").content
    });

    const body = JSON.stringify(params);
    const options = {
      method: 'POST',
      credentials: "same-origin",
      headers,
      body
    };

    let response = await fetch(url, options);
    let exoResponse = {'status':response.status,'statusText':response.statusText};
    
    try {
      const data = await response.json();
      exoResponse.data = data;
    } catch(err2) {
      if (url.indexOf("eventhandlerdo") !== -1) {
        exoResponse.data = {"msg":null,"messageList":[],"pageMessageList":[],"tableData":{},"sa":[],"parameters":[]};
      } else {
        exoResponse.data = {};
      }
    }
    return exoResponse;
  } catch (err) {
    console.log(err);
  }

}
