import { global as g } from '../globals.js';
import handleDelta from './deltaChangeHandler.js';
import sendRequest, {
  SELECT_URL,
  DO_URL,
  PAGE_OPEN_URL,
  PAGE_CLOSE_URL,
  CHANNEL_URL
} from './createRequest.fetch.js';
import openPage from '../page-builder';

/**
 *
 * @param sas -
 *          special actions
 * @returns
 */
export default async function processSpecialActions (sas) {
  // console.info(sas);
  const exo = document.querySelector(`.${g.exoContainer}`);
  let childPvsid;

  if (sas && sas.length > 0) {
    $('#waitModal').modal('show');
  } else {
    return;
  }

  console.log(sas);
  let lastEventType = -1, sasGrouped = [], selectsGrouped;
  for (let i = 0; i < sas.length; i++) {
    if (sas[i].eventType !== 5) {
      // all events but selects - no grouping
      sasGrouped.push(sas[i]);
    } else {
      // select events
      if (lastEventType === sas[i].eventType) {
        // previous event was also select
        selectsGrouped['longLinkList'].push(sas[i]['longLinkList'][0]);
      } else {
        // previous event was not select
        selectsGrouped = sas[i];
        sasGrouped.push(selectsGrouped);
      }
    }
    lastEventType = sas[i].eventType;
  }
  console.log(sasGrouped);

  for (let i = 0; i < sasGrouped.length; i++) {
    let sa = sasGrouped[i], response;

    switch (sa.eventType) {
      case 1:
      case 11:
        response = await sendRequest(PAGE_OPEN_URL, sa);
        childPvsid = response.data.pageViewSeqId;
        break;
      case 3:
        openPage(sa);
        break;
      case 4:
        sa.myPageViewSeqId = childPvsid;
        response = await sendRequest(PAGE_CLOSE_URL, sa);
        break;
      case 5:
        response = await sendRequest(SELECT_URL, sa);
        handleDelta(response.data);
        break;
      default:
        console.info(`Unexpected event type: ${sa.eventType}`);
    }
  }

  // const selects = sas.filter(p => p.eventType === 5);
  // // console.log(selects);
  // if (selects.length === sas.length) {
  //   // all SAs are selects. Combine them into one batch
  //   let sa = sas[0], response;
  //   for (let i = 1, pLen = sas.length; i < pLen; i++) {
  //     sa['longLinkList'].push(sas[i]['longLinkList'][0]);
  //   }
  //   response = await sendRequest(SELECT_URL, sa);
  //   handleDelta(response.data);

  // } else {
  //   // mixed SAs. In the future consider separating selects from page event
  //   // then run selects in one batch and the rest in a loop
  //   for (let i = 0, pLen = sas.length; i < pLen; i++) {
  //     let sa = sas[i], response;

  //     switch (sa.eventType) {
  //       case 1:
  //       case 11:
  //         response = await sendRequest(PAGE_OPEN_URL, sa);
  //         childPvsid = response.data.pageViewSeqId;
  //         break;
  //       case 3:
  //         openPage(sa);
  //         break;
  //       case 4:
  //         sa.myPageViewSeqId = childPvsid;
  //         response = await sendRequest(PAGE_CLOSE_URL, sa);
  //         break;
  //       case 5:
  //         response = await sendRequest(SELECT_URL, sa);
  //         handleDelta(response.data);
  //         break;
  //       default:
  //         console.info(`Unexpected event type: ${sa.eventType}`);
  //     }
  //   }
  // }

  if (sas && sas.length > 0) {
    $('#waitModal').modal('hide');
  }
}
